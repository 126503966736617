import checkmark from '@/assets/shuttle/icons/checkmark.svg';
import { Btn } from '@/components';
import { tLogPageView } from '@/utils/tracking';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';

const NoRoutePage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    tLogPageView();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.main}>
      <div className={styles.checkMark}>
        <img src={checkmark} alt="CheckMark" className={styles.checkMarkImg} />
      </div>
      <div className={styles.text}>
        Для этих адресов еще нет маршрута
        <span className={styles.textSmall}>Мы начали его создавать и свяжемся как будет готово</span>
      </div>
      <Btn color="black" onClick={() => navigate('/addresses')} customClassName={styles.btn}>
        Буду ждать
      </Btn>
    </div>
  );
};

export default NoRoutePage;
