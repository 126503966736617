import ellipse from '@/assets/shuttle/icons/circle.svg';
import bus from '@/assets/shuttle/img/busRed.png';
import { FC } from 'react';
import styles from './index.module.scss';

type BenefitsProps = {
  withBus?: boolean;
};

const Benefits: FC<BenefitsProps> = ({ withBus = false }) => {
  return (
    <>
      <div className={styles.features}>
        <div className={styles.featuresItem}>
          <div className={styles.featuresItemImg}>
            <img src={ellipse} alt="circle" className={styles.circle} />
          </div>
          <div className={styles.featuresItemText}>Удобный трансфер на работу</div>
        </div>
        <div className={styles.featuresItem}>
          <div className={styles.featuresItemImg}>
            <img src={ellipse} alt="circle" className={styles.circle} />
          </div>
          <div className={styles.featuresItemText}>Остановка в 2-х минутах пешком</div>
        </div>
        <div className={styles.featuresItem}>
          <div className={styles.featuresItemImg}>
            <img src={ellipse} alt="circle" className={styles.circle} />
          </div>
          <div className={styles.featuresItemText}>Довезем быстрее такси</div>
        </div>
        {withBus && (
          <div className={styles.bus}>
            <img src={bus} alt="bus" />
          </div>
        )}
      </div>
    </>
  );
};

export default Benefits;
