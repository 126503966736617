import { OptionsType, ValueType } from '@/components/shared/atoms/Select';
import { axiosInstance } from '@/utils/AxiosInstance';
import { getDateDayFullMonthString } from '@/utils/date';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

type latestSchedulesData = {
  phone: string;
};

export type RouteBusStopType = {
  bus_stop_id: number;
  name: string;
  arrival_time: string;
};

export type lastSuccessfulScheduleInfoType = {
  tour_id: number;
  pickup_rbs: RouteBusStopType;
  dropoff_rbs: RouteBusStopType;
};

export type UpcomingTourType = {
  date: string;
};

export const getUpcomingTours = createAsyncThunk<OptionsType>('address/upcoming-tours', async () => {
  return await axiosInstance.get<UpcomingTourType[]>('/upcoming-tours').then((data) => {
    return data.map((item) => {
      const date = new Date(item.date);
      return {
        value: item.date,
        label: `Поедем ${getDateDayFullMonthString(date)}`,
      };
    });
  });
});

export const getLatestSuccessfulSchedules = createAsyncThunk<lastSuccessfulScheduleInfoType[], latestSchedulesData>(
  'address/getLatestSuccessFulSchedules',
  async (data: latestSchedulesData) => {
    return await axiosInstance.get<lastSuccessfulScheduleInfoType[]>(
      `/latest-successful-schedules?phone=${encodeURIComponent(data.phone)}`
    );
  }
);

export const getSuggestionsAsync = createAsyncThunk('address/getSuggestions', async (text: string) => {
  const response = await axiosInstance.get(`/address-suggest?name=${text}`).then((data) => {
    return data;
  });
  return response;
});

export interface AddressState {
  isLoading: boolean;
  pickupAddress: AddressType | null;
  dropOffAddress: AddressType | null;
  latestSuccessfulTrips: lastSuccessfulScheduleInfoType[] | [];
  suggestions: [] | AddressType[];
  upcomingTourDates: OptionsType;
  selectedDate: ValueType;
}

const initialState: AddressState = {
  isLoading: false,
  pickupAddress: null,
  dropOffAddress: null,
  latestSuccessfulTrips: [] as lastSuccessfulScheduleInfoType[],
  suggestions: [],
  upcomingTourDates: [],
  selectedDate: null,
};

export const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    setIsLoading: (state: AddressState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setPickupAddress: (state: AddressState, action: PayloadAction<AddressType>) => {
      state.pickupAddress = action.payload;
    },
    setDropOffAddress: (state: AddressState, action: PayloadAction<AddressType>) => {
      state.dropOffAddress = action.payload;
    },
    setSelectedDate: (state: AddressState, action: PayloadAction<ValueType>) => {
      state.selectedDate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLatestSuccessfulSchedules.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getLatestSuccessfulSchedules.fulfilled,
      (state, action: PayloadAction<lastSuccessfulScheduleInfoType[]>) => {
        state.isLoading = false;
        state.latestSuccessfulTrips = action.payload;
      }
    );
    builder.addCase(getLatestSuccessfulSchedules.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getUpcomingTours.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUpcomingTours.fulfilled, (state, action) => {
      state.isLoading = false;
      state.upcomingTourDates = action.payload;
      if (state.upcomingTourDates.length > 0) {
        state.selectedDate = state.upcomingTourDates[0];
      }
    });
    builder.addCase(getSuggestionsAsync.pending, (state) => {
      state.isLoading = true;
      state.suggestions = [];
    });
    builder.addCase(getSuggestionsAsync.fulfilled, (state, action) => {
      const suggestions = action.payload as AddressType[];
      state.isLoading = false;
      state.suggestions = suggestions;
    });
  },
});

export const { setPickupAddress, setIsLoading, setDropOffAddress, setSelectedDate } = addressSlice.actions;

export const addressSelector = (state: RootState) => state.addressReducer;

export default addressSlice.reducer;
