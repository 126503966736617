import ellipse from '@/assets/shuttle/icons/ellipse.svg';
import { useAppDispatch } from '@/shuttle/app/hooks';
import { setFio } from '@/store/slices/mainSlice';
import { ChangeEvent, FC } from 'react';
import styles from './index.module.scss';

type FioFormProps = {
  circle?: boolean;
};

const FioForm: FC<FioFormProps> = ({ circle = true }) => {
  const dispatch = useAppDispatch();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setFio(e.target.value));
  };

  return (
    <form className={styles.fio}>
      <label htmlFor="fio" className={styles.fioText}>
        {circle ? <img src={ellipse} alt="circle" className={styles.circle} /> : <span className={styles.space}></span>}
        Введи фамилию и имя
      </label>
      <input type="text" id="fio" onChange={handleChange} placeholder="**** ****" />
    </form>
  );
};

export default FioForm;
