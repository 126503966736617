import { axiosInstance } from '@/utils/AxiosInstance';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';

export const getSuggestionsAsync = createAsyncThunk('form/getSuggestionsAsync', async (text: string) => {
  const response = await axiosInstance.get(`/address-suggest?name=${text}`).then((data) => {
    return data;
  });
  return response;
});

export interface SchoolState {
  isLoading: boolean;
  suggestions: [] | AddressType[];
}

const initialState: SchoolState = {
  isLoading: false,
  suggestions: [],
};

export const schoolSlice = createSlice({
  name: 'school',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSuggestionsAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSuggestionsAsync.fulfilled, (state, action) => {
      state.isLoading = false;
      state.suggestions = action.payload as AddressType[];
    });
  },
});

export const schoolSelector = (state: RootState) => state.schoolReducer;

export default schoolSlice.reducer;
