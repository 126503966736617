import man from '@/assets/shuttle/icons/man.png';
import classNames from 'classnames';
import { FC } from 'react';
import styles from './index.module.scss';

type TourProps = {
  data: tourDataType;
};

const Tour: FC<TourProps> = ({ data }) => {
  return (
    <div className={styles.tour}>
      <div className={styles.address}>
        <div className={styles.duration}>
          <img className={styles.durationImg} src={man} alt="man" />
          {data.tour.pickup_bus_stop.delay_minutes} мин
        </div>
        <div className={styles.dot}></div>
        <div className={styles.point}>{data.from_address.name}</div>
      </div>
      <div className={styles.stop}>
        <div className={styles.time}>{data.tour.pickup_bus_stop.arrival_time}</div>
        <div className={styles.empty}></div>
        <div className={classNames(styles.point, styles.busStop)}>{data.tour.pickup_bus_stop.name}</div>
      </div>
      <div className={styles.stop}>
        <div className={styles.time}>{data.tour.dropoff_bus_stop.arrival_time}</div>
        <div className={styles.empty}></div>
        <div className={classNames(styles.point, styles.busStop)}>{data.tour.dropoff_bus_stop.name}</div>
      </div>
      <div className={styles.address}>
        <div className={styles.duration}>
          <img className={styles.durationImg} src={man} alt="man" />
          {data.tour.dropoff_bus_stop.delay_minutes} мин
        </div>
        <div className={styles.dot}></div>
        <div className={styles.point}>{data.to_address.name}</div>
      </div>

      <div className={styles.stick}>
        <div className={styles.wrapper}>
          <div className={styles.stickSolid}></div>
        </div>
      </div>
    </div>
  );
};

export default Tour;
