import { LatLngTuple } from 'leaflet';

export const maxPassengerCount = 5;
export const subscriptionKind = {
  DAY: 'day',
  WEEK: 'week',
  WEEK_TRIAL: 'week_trial',
  MONTH: 'month',
  MONTH_SUB: 'month_sub',
  TWO_MONTH: 'two_month',
  DAY_CASH_TRIAL: 'day_cash_trial',
  DAY_ONLINE_TRIAL: 'day_online_trial',
  THREE_DAYS_ONLINE_TRIAL: 'three_days_online_trial',
};

export enum SubscriptionKind {
  DAY = 'day',
  WEEK = 'week',
  WEEK_TRIAL = 'week_trial',
  MONTH = 'month',
  MONTH_SUB = 'month_sub',
  TWO_MONTH = 'two_month',
  DAY_CASH_TRIAL = 'day_cash_trial',
  DAY_ONLINE_TRIAL = 'day_online_trial',
  THREE_DAYS_ONLINE_TRIAL = 'three_days_online_trial',
}

export const routeDirection = { TO: 'to', FROM: 'from' };
export const cityCenterCoords: LatLngTuple = [51.128207, 71.43042];
