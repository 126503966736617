import { Btn, PreLoader, RadioSelectBlock } from '@/components';
import { useAppDispatch, useAppSelector } from '@/projects/school/app/hooks';
import ellipse from '@/projects/school/assets/img/ellipse-full-red.svg';
import { SubscriptionKind, schools } from '@/projects/school/constants';
import {
  formSelector,
  setSubscriptionKind,
  submitSubscriptionFormAsync,
} from '@/projects/school/store/slices/formSlice';
import { Head, Title } from '@/school/components';
import { mainSelector } from '@/store/slices/mainSlice';
import { isValidNumber, parseNumber } from '@/utils/phone';
import { tLogPageView } from '@/utils/tracking';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './index.module.scss';

interface SubscriptionFormValues {
  subscriptionKind: SubscriptionKind;
}

export const Subscription: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { phone } = useAppSelector(mainSelector);
  const { grade, childCount, comment, fullName, homeAddress, subscriptionKind, isLoading } =
    useAppSelector(formSelector);
  const slug = params.school;

  const initialValues: SubscriptionFormValues = {
    subscriptionKind: subscriptionKind,
  };

  useEffect(() => tLogPageView(), []);

  if (!slug || !(slug in schools)) return <>No information</>;

  const monthlyAmount = schools[slug].monthlyPrices[childCount?.value as number];
  // const semiannualAmount = schools[slug].semiannualPrices[childCount?.value as number];
  // TODO: uncomment it once it will be needed.
  // const annualAmount = schools[slug].annualPrices[childCount?.value as number];

  const handleFormSubmit = async (values: SubscriptionFormValues) => {
    const parsedPhone = parseNumber(phone);

    await dispatch(
      submitSubscriptionFormAsync({
        phone: parsedPhone,
        school_id: schools[slug].schoolId,
        home_address: homeAddress,
        kind: values.subscriptionKind,
        child_grade: grade?.value,
        child_count: childCount?.value,
        full_name: fullName,
        comment: comment,
      })
    )
      .then(() => {
        navigate('../finish', { relative: 'path' });
      })
      .finally(() => {});
  };

  return (
    <div>
      {isLoading && <PreLoader showBackground={false} />}
      <div className={styles.wrapper}>
        <Head small />
        <Title marginTop={16}>Выберите абонемент</Title>
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          handleFormSubmit(values);
        }}
      >
        {({ values, handleSubmit, setFieldValue, isSubmitting }) => (
          <form>
            <div className={styles.selects}>
              <RadioSelectBlock
                value={SubscriptionKind.MONTHLY}
                onChange={() => {
                  dispatch(setSubscriptionKind(SubscriptionKind.MONTHLY));
                  setFieldValue('subscriptionKind', SubscriptionKind.MONTHLY);
                }}
                id="subcription-monthly"
                checked={values.subscriptionKind === SubscriptionKind.MONTHLY}
                name="subscription-kind"
                className={styles.kindMonthly}
                labelClassName={styles.label}
              >
                <div className={styles.title}>Месячный</div>
                <div className={styles.amount}>{monthlyAmount} тг</div>
              </RadioSelectBlock>
              {/* <RadioSelectBlock
                value={SubscriptionKind.SEMIANNUAL}
                onChange={() => {
                  dispatch(setSubscriptionKind(SubscriptionKind.SEMIANNUAL));
                  setFieldValue('subscriptionKind', SubscriptionKind.SEMIANNUAL);
                }}
                id="subcription-semiannual"
                checked={values.subscriptionKind === SubscriptionKind.SEMIANNUAL}
                name="subscription-kind"
                className={styles.kindSemiannual}
                labelClassName={styles.label}
              >
                <div className={styles.title}>До конца учебного года</div>
                <div className={styles.amount}>{semiannualAmount} тг</div>
              </RadioSelectBlock> */}
              {/* <RadioSelectBlock
                value={SubscriptionKind.ANNUAL}
                onChange={() => {
                  dispatch(setSubscriptionKind(SubscriptionKind.ANNUAL));
                  setFieldValue('subscriptionKind', SubscriptionKind.ANNUAL);
                }}
                id="subcription-annual"
                checked={values.subscriptionKind === SubscriptionKind.ANNUAL}
                name="subscription-kind"
                labelClassName={styles.label}
              >
                <div className={styles.title}>Годовой</div>
                <div className={styles.amount}>{annualAmount} тг</div>
              </RadioSelectBlock> */}
            </div>
            <div className={styles.featuresItem}>
              <div className={styles.featuresItemImg}>
                <img src={ellipse} alt="circle" className={styles.circle} />
              </div>
              <div className={styles.featuresItemText}>Оплачивать нужно только после добавления на маршрут</div>
            </div>
            <Btn
              color="black"
              isFixedBottom={true}
              onClick={handleSubmit}
              disabled={isSubmitting || !isValidNumber(phone) || !fullName || !homeAddress}
            >
              Продолжить
            </Btn>
          </form>
        )}
      </Formik>
    </div>
  );
};
