import { DriverData } from '@/@types/shuttle/driver';
import { SubscriptionKind, routeDirection } from '@/shuttle/constants';
import { RootState } from '@/shuttle/store/store';
import { setToaster } from '@/store/slices/toasterSlice';
import { axiosInstance } from '@/utils/AxiosInstance';
import { getDateDayFullMonthString, getDateWeekdayString } from '@/utils/date';
import { purchaseAmount } from '@/utils/shuttle/purchase';
import { tLogStartPurchase, tSetCustomerIdByPhone } from '@/utils/tracking';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

interface BuySubscriptionForm {
  tour_id: number;
  phone: string;
  passenger_count: number;
  kind: SubscriptionKind;
  pickup_point_id: string | number | null;
  delivery_point_id: string | number | null;
  is_mobile: boolean;
  start_date?: string | null;
}

interface FieldError {
  field: string;
  message: string;
}
interface ValidationError {
  code: number;
  errors: FieldError[];
  message: string;
  status_code: number;
}

export const buySubscriptionAsync = createAsyncThunk(
  'payment/buySubscriptionAsync',
  async (data: BuySubscriptionForm, { dispatch }) => {
    try {
      const response = await axiosInstance.post<BuySubscriptionResponse>('/subscriptions', data);
      return {
        phone: data.phone,
        pickup_stop_id: data.pickup_point_id,
        dropoff_stop_id: data.delivery_point_id,
        data: response,
        kind: data.kind,
        passenger_count: data.passenger_count,
      };
    } catch (err) {
      const error = err as AxiosError;
      let message = 'Произошла ошибка при оплате.';
      const data = error.response?.data as ValidationError;
      if (data && data.errors) {
        const errors = data.errors.map((e) => e.message);
        message += '\n' + errors.join('\r\n');
      }
      dispatch(
        setToaster({
          type: 'warning',
          message: message,
          isVisible: true,
        })
      );
      throw err;
    }
  }
);

type tourInfoData = {
  tourId: string;
  paramsString: string;
};

interface BuySubscriptionResponse {
  redirect_url: string;
}

interface BuySubscriptionPayload {
  phone: string;
  pickup_stop_id: string | number | null;
  dropoff_stop_id: string | number | null;
  data: BuySubscriptionResponse;
  kind: SubscriptionKind;
  passenger_count: number;
}

interface tourInfo {
  one_trip_price: number;
  weekly_subscription_price: number;
  week_trial_subscription_price: number;
  monthly_subscription_price: number;
  monthly_subscription_discount_percent: number;
  subscription_price: number;
  day_cash_trial_subscription_price: number;
  day_online_trial_subscription_price: number;
  three_days_online_trial_subscription_price: number;
  driver: DriverData;
  route_name: string;
  route_direction: string;
  upcoming_tour_dates: string[];
  bonus_amount: number;
}

interface tourInfoResponse extends tourInfo {
  isMobile: boolean;
}

export type upcomingTourDate = {
  value: Date;
  label: string;
};

export const getTourInfoAsync = createAsyncThunk<tourInfo, tourInfoData>(
  'payment/getTourInfoAsync',
  async (data: tourInfoData) => {
    return await axiosInstance
      .get(`/tour-info/${data.tourId}${data.paramsString ? `?${data.paramsString}` : ''}`)
      .then((data) => {
        const tour = data as tourInfo;
        return tour;
      });
  }
);

export interface PaymentState {
  isLoading: boolean;
  oneTripPrice: number | null;
  dayOnlineTrialSubscriptionPrice: number | null;
  threeDaysOnlineTrialSubscriptionPrice: number | null;
  weeklySubscriptionPrice: number | null;
  weeklyTrialSubscriptionPrice: number | null;
  monthlySubscriptionPrice: number | null;
  montlySubscriptionDiscountPercent: number | null;
  subscriptionPrice: number | null;
  driver: DriverData | null;
  upcomingTourDates: upcomingTourDate[] | null;
  subscriptionKind: SubscriptionKind;
  bonusAmount: number | null;
  routeDirection: string;
}

const initialState: PaymentState = {
  isLoading: true,
  oneTripPrice: null,
  dayOnlineTrialSubscriptionPrice: null,
  threeDaysOnlineTrialSubscriptionPrice: null,
  weeklySubscriptionPrice: null,
  weeklyTrialSubscriptionPrice: null,
  monthlySubscriptionPrice: null,
  montlySubscriptionDiscountPercent: null,
  subscriptionPrice: null,
  driver: null,
  upcomingTourDates: null,
  subscriptionKind: SubscriptionKind.DAY,
  bonusAmount: null,
  routeDirection: '',
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setSubscriptionKind: (state, action) => {
      state.subscriptionKind = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTourInfoAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTourInfoAsync.fulfilled, (state, action) => {
      const response = action.payload as tourInfoResponse;
      state.isLoading = false;

      //one
      state.oneTripPrice = response.one_trip_price;
      state.dayOnlineTrialSubscriptionPrice = response.day_online_trial_subscription_price;
      // three
      if (response.three_days_online_trial_subscription_price) {
        state.threeDaysOnlineTrialSubscriptionPrice = response.three_days_online_trial_subscription_price;
      }
      //week
      state.weeklySubscriptionPrice = response.weekly_subscription_price;
      state.weeklyTrialSubscriptionPrice = response.week_trial_subscription_price;
      //monthly
      state.monthlySubscriptionPrice = response.monthly_subscription_price;
      state.montlySubscriptionDiscountPercent = response.monthly_subscription_discount_percent;
      //subscription
      state.subscriptionPrice = response.subscription_price;
      //driver
      state.driver = {
        ...response.driver,
        route_name: response.route_name,
      };
      //route direction
      state.routeDirection = response.route_direction;

      if (response.upcoming_tour_dates) {
        state.upcomingTourDates = response.upcoming_tour_dates.map((value: string) => {
          const date = new Date(value);

          return {
            value: date,
            label: `${getDateWeekdayString(date)}, ${getDateDayFullMonthString(date)}`,
          } as upcomingTourDate;
        });
      }

      if (response.route_direction === routeDirection.TO) {
        state.subscriptionKind = SubscriptionKind.MONTH_SUB;
      }

      if (response.bonus_amount) {
        state.bonusAmount = response.bonus_amount;
      }
    });
    builder.addCase(getTourInfoAsync.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(buySubscriptionAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(buySubscriptionAsync.fulfilled, (state, action: PayloadAction<BuySubscriptionPayload>) => {
      state.isLoading = false;

      localStorage.setItem('pickup_point_id', String(action.payload.pickup_stop_id));
      localStorage.setItem('delivery_point_id', String(action.payload.dropoff_stop_id));

      localStorage.setItem('phone', action.payload.phone);
      // FIXME: Probably, it is safe to remove it from here.
      tSetCustomerIdByPhone(action.payload.phone);

      tLogStartPurchase(
        purchaseAmount(
          action.payload.kind,
          action.payload.passenger_count,
          state.oneTripPrice as number,
          state.weeklySubscriptionPrice as number,
          state.monthlySubscriptionPrice as number
        )
      );
      window.location.href = action.payload.data.redirect_url;
    });
    builder.addCase(buySubscriptionAsync.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setSubscriptionKind } = paymentSlice.actions;

export const paymentSelector = (state: RootState) => state.paymentReducer;

export default paymentSlice.reducer;
