import React, { useEffect } from 'react';

import classNames from 'classnames';
import L, { LatLngTuple } from 'leaflet';
import { useMap } from 'react-leaflet';

import { Btn, Map } from '@/components';

import styles from './index.module.scss';

import backWhite from './assets/backWhite.svg';
import destinationSrc from './assets/destination_icon.png';
import geo from './assets/geo.png';
import locationSrc from './assets/location.png';

const locationIcon = new L.Icon({
  iconUrl: locationSrc,
  iconSize: [56, 56],
  iconAnchor: [22, 30],
});

const destinationIcon = new L.Icon({
  iconUrl: destinationSrc,
  iconSize: [30, 46],
  iconAnchor: [10, 46],
  popupAnchor: [0, -37],
});

type AddressSelectMapProps = {
  onSelect: (latLng: L.LatLng) => void;
  onBackBtnClick: () => void;
  forwardClassName?: string;
  mapCenter: LatLngTuple;
};

const AddressSelectMap: React.FC<AddressSelectMapProps> = (props) => {
  const [map, setMap] = React.useState<L.Map | null>(null);
  const mapRef = React.useRef<L.Map | null>(null);
  const markerRef = React.useRef<L.Marker | null>(null);
  const locationMarkerRef = React.useRef<L.Marker | null>(null);
  const [markerPosition, setMarkerPosition] = React.useState<L.LatLng | null>(null);

  function MapEventComponent() {
    const map = useMap();
    useEffect(() => setMap(map));
    return null;
  }

  useEffect(() => {
    if (!map) return;

    // Clear points first.
    map.eachLayer((layer) => {
      if (layer instanceof L.Marker || layer instanceof L.Polyline) map.removeLayer(layer);
    });

    markerRef.current = L.marker(props.mapCenter, {
      draggable: true,
      autoPan: true,
      icon: destinationIcon,
    }).addTo(map!);
    setMarkerPosition(L.latLng(props.mapCenter));

    function onLocationFound(e: L.LocationEvent) {
      if (locationMarkerRef.current) {
        map?.flyTo(e.latlng);
      } else {
        // Location icon.
        locationMarkerRef.current = L.marker(e.latlng, {
          icon: locationIcon,
        }).addTo(map!);
      }
      markerRef.current?.setLatLng(e.latlng);
      setMarkerPosition(e.latlng);
    }

    function onLocationError(e: L.ErrorEvent) {
      console.log(e.message);
    }

    map.on('locationfound', onLocationFound);
    map.on('locationerror', onLocationError);

    map.locate({ setView: true, maxZoom: 16 });

    setTimeout(() => {
      map.on('move', function () {
        markerRef.current!.setLatLng(map.getCenter());
        setMarkerPosition(map.getCenter());
      });

      markerRef.current!.on('move', (e) => setMarkerPosition(e.target.getLatLng()));
    }, 500);
  }, [map, props.mapCenter]);

  return (
    <div className={styles.main}>
      <Map ref={mapRef} center={props.mapCenter} scrollWheelZoom={'center'}>
        <MapEventComponent />
      </Map>
      <div className={styles.btns}>
        <div className={styles.geo}>
          <img src={geo} alt="Geo" onClick={() => map?.locate()} />
        </div>
        <div className={styles.navigation}>
          <Btn
            color="black"
            customClassName={styles.backBtn}
            onClick={() => {
              props.onBackBtnClick();
            }}
          >
            <img src={backWhite} alt="back" />
          </Btn>
          <Btn
            color="black"
            customClassName={classNames(styles.forwardBtn, props.forwardClassName)}
            onClick={() => {
              props.onSelect(markerPosition!);
            }}
          >
            Выбрать
          </Btn>
        </div>
      </div>
    </div>
  );
};

export default AddressSelectMap;
