import classNames from 'classnames';
import React, { ForwardRefRenderFunction, useEffect, useState } from 'react';
import styles from './index.module.scss';

import mapIcon from './assets/map.svg';

import { TAddressSource, TAddressType, tLogCustomEvent } from '@/utils/tracking';

enum AddressTypeEnum {
  PICKUP = 0,
  DROPOFF = 1,
}

type SearchInputProps = {
  type: AddressTypeEnum;
  setSelectedData: (value: AddressType | null) => void;
  selectionData?: AddressType[] | null;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  onMapBtnClick: () => void;
  inputClassName?: string;
  mapBtnClassName?: string;
  selectionClassName?: string;
  closeBtnClassName?: string;
  placeholder?: string;
  customClassName?: string;
  customSelectionClassName?: string;
  value: string;
  forwardedRef: React.RefObject<SearchInputHandle | null>;
  id?: string;
  disabled?: boolean;
};

export type SearchInputHandle = {
  setValue: (value: string) => void;
};

const SearchInput: ForwardRefRenderFunction<SearchInputHandle, SearchInputProps> = (props) => {
  const [focused, setFocused] = useState(false);
  const [isSelectionShowed, setIsSelectionShowed] = useState(false);
  const [value, setValue] = useState(props.value);

  React.useImperativeHandle(
    props.forwardedRef,
    () => {
      return {
        setValue(value: string) {
          setValue(value);
        },
      };
    },
    []
  );

  useEffect(() => {
    if (!value) {
      setIsSelectionShowed(false);
    } else {
      setIsSelectionShowed(true);
    }
  }, [value]);

  return (
    <div className={classNames(styles.inputBlock, props.customClassName)}>
      <input
        id={props.id}
        placeholder={props.placeholder}
        className={classNames(styles.input, props.inputClassName)}
        onFocus={() => setFocused(true)}
        onBlur={() => {
          props.onBlur && props.onBlur();
          setTimeout(() => {
            setFocused(false);
            setIsSelectionShowed(false);
          }, 300);
        }}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          props.onChange && props.onChange(e.target.value);
        }}
        disabled={props.disabled || false}
      />
      {value && (
        <span
          className={classNames(styles.closeBtn, props.closeBtnClassName)}
          onClick={() => {
            setValue('');
            props.setSelectedData(null);
          }}
        >
          &#x2715;
        </span>
      )}
      <div className={classNames(styles.mapBtn, props.mapBtnClassName)} onClick={() => props.onMapBtnClick()}>
        Карта
      </div>
      {focused && isSelectionShowed && props.selectionData && props.selectionData.length > 0 && (
        <div className={classNames(styles.selection, props.customSelectionClassName)}>
          {props.selectionData.map((s, index) => (
            <div
              key={index}
              className={styles.select}
              onClick={() => {
                setValue(s.name);
                props.setSelectedData(s);
                if (props.type === AddressTypeEnum.PICKUP) {
                  tLogCustomEvent('select_address', {
                    type: TAddressType.PICKUP,
                    source: TAddressSource.AUTOCOMPLETE,
                    address: s.name,
                  });
                } else {
                  tLogCustomEvent('select_address', {
                    type: TAddressType.DROPOFF,
                    source: TAddressSource.AUTOCOMPLETE,
                    address: s.name,
                  });
                }
              }}
            >
              <img src={mapIcon} alt="map" className={styles.selectImg} />
              <div className={classNames(styles.selectName, props.selectionClassName)}>
                {s.name.replace(', Казахстан', '')}
              </div>
            </div>
          ))}
        </div>
      )}
      {focused && isSelectionShowed && props.selectionData && props.selectionData.length === 0 && (
        <div className={classNames(styles.selection, props.customSelectionClassName)}>
          <div
            className={styles.select}
            onClick={() => {
              setIsSelectionShowed(false);
            }}
          >
            <div className={styles.selectName}> Попробуйте полный адрес</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchInput;
export { AddressTypeEnum };
