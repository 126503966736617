import { Btn } from '@/components';
import { useAppDispatch, useAppSelector } from '@/shuttle/app/hooks';
import { getSubscriptionInfoAsync, subscriptionSelector } from '@/shuttle/store/slices/subscriptionSlice';
import { getDateDayString } from '@/utils/date';
import { FC, useEffect } from 'react';
import styles from './index.module.scss';

import checkmark from '@/assets/shuttle/icons/checkmark.svg';
import logo from '@/assets/shuttle/img/logo.png';
import Stub from '@/components/shuttle/molecules/Stub';
import { Badges, CallCenterBtns } from '@/fake_shuttle/components/atoms';
import { mainSelector } from '@/store/slices/mainSlice';
import { setToaster } from '@/store/slices/toasterSlice';
import { useNavigate } from 'react-router-dom';

declare global {
  interface Window {
    RideFinishHandler: { postMessage(name: string): void };
  }
}

type SubscriptionControllerProps = {
  subId: string;
};

const SubscriptionController: FC<SubscriptionControllerProps> = ({ subId }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isMobile } = useAppSelector(mainSelector);
  const { data } = useAppSelector(subscriptionSelector);

  useEffect(() => {
    dispatch(
      getSubscriptionInfoAsync({
        id: subId,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subId]);

  useEffect(() => {
    if (data === null) {
      dispatch(
        setToaster({
          type: 'warning',
          message: 'Подписка не найдена',
          isVisible: true,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function flutterEvent() {
    if (window.RideFinishHandler) {
      window.RideFinishHandler.postMessage('');
    }
  }

  return (
    <div className={styles.info}>
      {data === null ? <Stub /> : null}
      {data ? (
        <>
          <div className={styles.checkMark}>
            <img src={checkmark} alt="check-mark" />
          </div>
          <div className={styles.title}>Твое место забронировано</div>
          <div className={styles.subscription}>
            <div className={styles.subscriptionFields}>
              <div>Маршрут:</div>
              <div>Даты:</div>
            </div>
            <div className={styles.subscriptionValues}>
              <div>{data.route_name}</div>
              <div>
                {getDateDayString(new Date(Date.parse(data.valid_since_date)))}
                <span> - </span>
                {getDateDayString(new Date(Date.parse(data.valid_until_date)))}
              </div>
            </div>
            <div className={styles.subscriptionRoute}>
              {data.driver && (
                <div className={styles.driver}>
                  <div className={styles.driverPhoto}>
                    {data.driver.photo_url ? (
                      <img src={data.driver.photo_url} alt="driver" className={styles.driverPhotoImg} />
                    ) : (
                      <img src={logo} alt="driver" className={styles.driverPhotoImg} />
                    )}
                  </div>
                  <div className={styles.driverText}>
                    <div className={styles.driverTextName}>{data.driver.name}</div>
                    <div className={styles.driverTextCar}>{data.driver.car_name}</div>
                    <div className={styles.driverTextNumberPlate}>{data.driver.car_number_plate}</div>
                  </div>
                </div>
              )}
              {data.pickup_point_name && data.delivery_point_name && (
                <div className={styles.subscriptionRouteInfo}>
                  <div className={styles.timeRange}>
                    <div className={styles.timeRangeStart}>{data.pickup_point_departure_time}</div>
                    <div>{data.delivery_point_departure_time}</div>
                    <div className={styles.timeRangeStick}></div>
                  </div>
                  <div className={styles.subscriptionRouteInfoStops}>
                    <div className={styles.subscriptionRouteInfoStopsItem}>{data.pickup_point_name}</div>
                    <div className={styles.subscriptionRouteInfoStopsItem}>{data.delivery_point_name}</div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {isMobile ? (
            <Btn
              color="black"
              onClick={() => {
                if (isMobile) {
                  flutterEvent();
                } else {
                  navigate(`/`);
                }
              }}
              customClassName={styles.btn}
            >
              Отлично
            </Btn>
          ) : (
            <>
              <CallCenterBtns />
              <Badges />
            </>
          )}
        </>
      ) : null}
    </div>
  );
};

export default SubscriptionController;
