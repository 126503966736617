import logo from '@/projects/school/assets/img/logo.svg';
import styles from './index.module.scss';

export function Home() {
  return (
    <div className={styles.main}>
      <img src={logo} alt="UvU" />
    </div>
  );
}
