import ellipse from '@/assets/shuttle/icons/ellipse.svg';
import giftRibbon from '@/assets/shuttle/img/gift_ribbon.png';
import { setSubscriptionKind } from '@/projects/shuttle/store/slices/paymentSlice';
import { useAppDispatch } from '@/shuttle/app/hooks';
import { SubscriptionKind, subscriptionKind } from '@/shuttle/constants';
import classNames from 'classnames';
import { FC, useEffect } from 'react';
import styles from './index.module.scss';

type TrialWeekProps = {
  subscriptionKindValue: string | null;
  weekTrialPaymentAmountValue: number | null;
};

const TrialWeek: FC<TrialWeekProps> = ({ subscriptionKindValue, weekTrialPaymentAmountValue }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSubscriptionKind(SubscriptionKind.WEEK_TRIAL));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={classNames(styles.subscriptionKind, styles.subscriptionKindWeeklyTrial)}>
        <input
          type="radio"
          name="kind"
          id="subscription-kind-weekly-trial"
          className={styles.subscriptionKindInput}
          value={subscriptionKind.WEEK_TRIAL}
          defaultChecked={subscriptionKindValue === subscriptionKind.WEEK_TRIAL}
        />
        <label htmlFor="subscription-kind-weekly-trial" className={styles.subscriptionKindItem}>
          <div>
            {weekTrialPaymentAmountValue ? (
              <div className={styles.subscriptionKindAmount}>
                Пробная неделя <br />
                {weekTrialPaymentAmountValue} тг
                <span className={styles.subscriptionKindAllAmount}>
                  5 поездок по {weekTrialPaymentAmountValue / 5} тг
                </span>
              </div>
            ) : null}
            <div className={styles.subscriptionKindImg}>
              <img src={giftRibbon} alt="gift" />
            </div>
          </div>
        </label>
      </div>
      <div className={styles.trialInfo}>
        <div className={styles.trialInfoTitle}>
          <div>
            <img src={ellipse} alt="circle" className={styles.circle} />
          </div>
          <div>
            Потом у тебя активируется месячная подписка за <strong>14 990 тг</strong>
            <br /> (680 тг за поездку в час пик)
          </div>
        </div>
        <div className={styles.trialInfoTitle}>
          <div>
            <img src={ellipse} alt="circle" className={styles.circle} />
          </div>
          <div>Отменить подписку или запросить возврат можно в любое время</div>
        </div>
      </div>
    </>
  );
};

export default TrialWeek;
