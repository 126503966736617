import Stub from '@/components/shuttle/molecules/Stub';
import { setToaster } from '@/store/slices/toasterSlice';
import { useAppDispatch } from '@/travel_shuttle/app/hooks';
import SubscriptionController from '@/travel_shuttle/components/containers/SubscriptionController';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const SubscriptionInfo: React.FC = () => {
  const dispatch = useAppDispatch();
  const { uuid: sub_uuid } = useParams();

  useEffect(() => {
    if (!sub_uuid) {
      dispatch(
        setToaster({
          type: 'warning',
          message: 'Не указано ID подписки',
          isVisible: true,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sub_uuid]);

  return !sub_uuid ? <Stub /> : <SubscriptionController subId={sub_uuid} />;
};

export default SubscriptionInfo;
