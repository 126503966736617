import { addressType } from '@/@types/shuttle/address';
import calendar from '@/assets/shuttle/icons/calendar.svg';
import { AddressSelectMap, Btn, PreLoader, SearchInput, Select } from '@/components';
import { OptionsType } from '@/components/shared/atoms/Select';
import { AddressTypeEnum, SearchInputHandle } from '@/components/shared/molecules/SearchInput';
import { FakePhoneNumber } from '@/constants';
import { useAppDispatch, useAppSelector } from '@/travel_shuttle/app/hooks';
import logo from '@/travel_shuttle/assets/icons/uvuBorovoeLogo.png';
// Use Select.
import circle from '@/assets/shuttle/icons/circle.svg';
import sprinter from '@/travel_shuttle/assets/img/sprinter.png';
import TravelSelect from '@/travel_shuttle/components/atoms/Select';
import {
  addressSelector,
  getLatestSuccessfulSchedules,
  getSuggestionsAsync,
  setDropOffAddress,
  setPickupAddress,
  setSelectedDate,
} from '@/travel_shuttle/store/slices/addressSlice';
import { getAddressName } from '@/utils/address';
import { useDebounce } from '@/utils/debounce';
import { isValidNumber } from '@/utils/phone';
import { TAddressSource, TAddressType, tLogCustomEvent } from '@/utils/tracking';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import styles from './index.module.scss';

import { cities } from '@/constants';
import { setToaster } from '@/store/slices/toasterSlice';
import { getDateDayFullMonthString, getDateWeekdayString, getNextWeekday } from '@/utils/date';
import L from 'leaflet';

export type AddressControllerProps = {
  phone: string;
  isMobile: boolean;
  onPhoneModalShow: () => void;
  onSubmit: () => void;
  modal: string | null;
};

enum addressSelectChoices {
  FROM,
  TO,
}

const dropoffAddresses: addressType[] = [
  {
    name: 'Боровое',
    location: {
      lat: 53.08815,
      lng: 70.297096,
    },
  },
  {
    name: 'Щучинск',
    location: {
      lat: 52.94226,
      lng: 70.199192,
    },
  },
];

function getNextWeekendDates(): OptionsType {
  const today = new Date();
  const nextSaturday = getNextWeekday(6, today);
  const nextSunday = getNextWeekday(0, today);

  return [
    {
      value: nextSaturday.toISOString().split('T')[0],
      label: `${getDateWeekdayString(nextSaturday)}, ${getDateDayFullMonthString(nextSaturday)}`,
    },
    {
      value: nextSunday.toISOString().split('T')[0],
      label: `${getDateWeekdayString(nextSunday)}, ${getDateDayFullMonthString(nextSunday)}`,
    },
  ];
}

const dates = getNextWeekendDates();

const AddressesController: FC<AddressControllerProps> = ({ phone, onPhoneModalShow, onSubmit }) => {
  const { isLoading, suggestions, pickupAddress, dropOffAddress, selectedDate } = useAppSelector(addressSelector);
  const dispatch = useAppDispatch();

  const pickupAddressForwardedRef = useRef<SearchInputHandle>(null);
  const dropOffAddressForwardedRef = useRef<SearchInputHandle>(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const [canSubmit, setCanSubmit] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [pickupStartedTyping, SetPickupStartedTyping] = useState(false);
  const [routeDirection, setRouteDirection] = useState<addressSelectChoices>(addressSelectChoices.FROM);

  const localPhone = useMemo(() => {
    if (phone && phone !== FakePhoneNumber) {
      return phone;
    }
    return null;
  }, [phone]);

  useEffect(() => {
    if (localPhone) {
      setInitialLoading(true);
      dispatch(
        getLatestSuccessfulSchedules({
          phone: localPhone,
        })
      ).finally(() => setInitialLoading(false));
    } else {
      setInitialLoading(false);
    }
  }, [localPhone, dispatch]);

  useEffect(() => {
    dispatch(setSelectedDate(dates[0]));
  }, [dispatch]);

  const onAddressSelect = async (latLng: L.LatLng) => {
    const addressObj: addressType = {
      name: 'Точка на карте',
      location: {
        lat: latLng.lat,
        lng: latLng.lng,
      },
    };

    try {
      const result = await getAddressName(addressObj);

      if (result.status === 'success') {
        if (routeDirection === addressSelectChoices.FROM) {
          dispatch(setPickupAddress(result.data));
          tLogCustomEvent('select_address', {
            type: TAddressType.PICKUP,
            source: TAddressSource.MAP,
            address: result.data.name,
          });
          pickupAddressForwardedRef.current!.setValue(result.data.name);
          if (dropOffAddress) setCanSubmit(true);
        } else {
          dispatch(setDropOffAddress(result.data));
          tLogCustomEvent('select_address', {
            type: TAddressType.DROPOFF,
            source: TAddressSource.MAP,
            address: result.data.name,
          });
          dropOffAddressForwardedRef.current!.setValue(result.data.name);
          if (pickupAddress) setCanSubmit(true);
        }
      }
    } catch {
      dispatch(
        setToaster({
          isVisible: true,
          type: 'error',
          message: 'Произошла ошибка. Попробуйте еще раз или выберите другой адрес.',
        })
      );
    }
    setShowMap(false);
  };

  const getSuggestions = (value: string) => {
    if (value.trim() === '') return;

    dispatch(getSuggestionsAsync(value));
  };

  const handleInputChange = async (value: string, direction: string) => {
    if (!value) return;

    const data = { name: value, location: null };
    if (direction === TAddressType.PICKUP) {
      dispatch(setPickupAddress(data));
    } else {
      dispatch(setDropOffAddress(data));
    }
  };

  const delayedGetSuggestions = useDebounce(getSuggestions, 1000);

  return (
    <>
      {isLoading && <PreLoader />}
      {initialLoading && <PreLoader showText showBackground />}
      {showMap && (
        <AddressSelectMap
          onSelect={onAddressSelect}
          onBackBtnClick={() => setShowMap(false)}
          forwardClassName="ConfirmLocationBtn"
          mapCenter={cities.astana.center}
        />
      )}
      <div className={styles.logo}>
        <img src={logo} alt="UvU" />
      </div>
      <div className={styles.addresses}>
        <>
          <div className={styles.form}>
            <div className={styles.formStick} />
            {
              <SearchInput
                type={AddressTypeEnum.PICKUP}
                inputClassName="PickupAddressSearchInput"
                mapBtnClassName="PickupAddressMapBtn"
                selectionClassName="PickupAddressSelection"
                placeholder="Откуда едем"
                selectionData={suggestions}
                setSelectedData={(value) => {
                  dispatch(setPickupAddress(value!));
                  if (value && dropOffAddress) setCanSubmit(true);
                }}
                customSelectionClassName={styles.fromSelection}
                value={pickupAddress ? pickupAddress.name : ''}
                forwardedRef={pickupAddressForwardedRef}
                onChange={(value) => {
                  delayedGetSuggestions(value);
                  handleInputChange(value, TAddressType.PICKUP);

                  if (!pickupStartedTyping) {
                    SetPickupStartedTyping(true);
                    tLogCustomEvent('started_typing_address', { type: TAddressType.PICKUP });
                  }
                }}
                onBlur={() => SetPickupStartedTyping(false)}
                onMapBtnClick={() => {
                  setRouteDirection(addressSelectChoices.FROM);
                  setShowMap(true);
                }}
              />
            }
            {
              <TravelSelect
                placeholder="Боровое или Щучинск?"
                selectionData={dropoffAddresses}
                setSelectedData={(value: AddressType | null) => {
                  dispatch(setDropOffAddress(value!));
                  if (value?.name) {
                    tLogCustomEvent('select_address', {
                      type: TAddressType.DROPOFF,
                      source: TAddressSource.AUTOCOMPLETE,
                      address: value.name,
                    });
                    if (value && pickupAddress) setCanSubmit(true);
                  }
                }}
                customClassName={styles.dropoffInput}
                customSelectionClassName={styles.toSelection}
                value={
                  dropoffAddresses.length == 1 ? dropoffAddresses[0].name : dropOffAddress ? dropOffAddress.name : ''
                }
                forwardedRef={dropOffAddressForwardedRef}
              />
            }
          </div>
          <div className={styles.formDate}>
            <img src={calendar} alt="calendar" className={styles.calendar} />
            <Select
              options={dates}
              value={selectedDate}
              menuClassName={styles.menuClassName}
              onChange={(value) => {
                dispatch(setSelectedDate(value));
              }}
            />
          </div>

          <ul className={styles.features}>
            <li>
              <img src={circle} alt="circle" className={styles.circle} /> Комфортные и пунктуальные шаттлы
            </li>
            <li>
              <img src={circle} alt="circle" className={styles.circle} /> Профессиональные водители
            </li>
            <li>
              <img src={circle} alt="circle" className={styles.circle} /> Шаттл довезет куда надо
            </li>
          </ul>

          <div className={styles.bus}>
            <img src={sprinter} alt="sprinter" />
          </div>

          <Btn
            type="button"
            color="black"
            onClick={() => {
              if (isValidNumber(phone) && canSubmit) {
                onSubmit();
              } else {
                onPhoneModalShow();
              }
            }}
            disabled={isLoading || !pickupAddress || !dropOffAddress}
            isFixedBottom={true}
          >
            Продолжить
          </Btn>
        </>
      </div>
    </>
  );
};

export default AddressesController;
