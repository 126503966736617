import { RootState } from '@/store/store';
import { tSetCustomerIdByPhone } from '@/utils/tracking';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

export interface MainState {
  isMobile: boolean;
  phone: string;
  fio: string;
}

const initialState: MainState = {
  isMobile: false,
  phone: '',
  fio: '',
};

export const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setIsMobile: (state, action: PayloadAction<boolean>) => {
      state.isMobile = action.payload;
    },
    setPhone: (state, action: PayloadAction<string>) => {
      const phone = action.payload;

      const [firstName, lastName] = state.fio ? state.fio.split(' ') : ['', ''];
      tSetCustomerIdByPhone(phone, firstName, lastName);

      state.phone = phone;

      Sentry.configureScope((scope) => {
        // TODO: Change it to use customer id.
        scope.setUser({ id: phone });
      });
    },
    setFio: (state, action: PayloadAction<string>) => {
      state.fio = action.payload;
    },
  },
});

export const { setIsMobile, setPhone, setFio } = mainSlice.actions;

export const mainSelector = (state: RootState) => state.mainReducer;

export default mainSlice.reducer;
