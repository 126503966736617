import { useIsMobileFromSearchParams, usePhoneFromSearchParams } from '@/hooks/useSearchParams';
import NoRoute from '@/pages/shuttle/NoRoute';
import { NotFoundPage } from '@/pages/shuttle/NotFoundPage';
import { mainSelector } from '@/store/slices/mainSlice';
import { useAppSelector } from '@/travel_shuttle/app/hooks';
import Addresses from '@/travel_shuttle/pages/Adresses';
import Payment from '@/travel_shuttle/pages/Payment';
import SubscriptionInfo from '@/travel_shuttle/pages/SubscriptionInfo';
import Tours from '@/travel_shuttle/pages/Tours';
import { shuttleSelector } from '@/travel_shuttle/store/slices/shuttleSlice';
import { getRandomYandexAPIKey } from '@/utils/yandex';
import { FC, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ymaps from 'ymaps';

import './index.scss';
import Register from './pages/Register';

const App: FC = () => {
  const { phone, isMobile } = useAppSelector(mainSelector);
  const { isTitleHidden } = useAppSelector(shuttleSelector);
  const memorizedYandexAPIKey = useMemo(() => getRandomYandexAPIKey(), []);
  ymaps.load(`https://api-maps.yandex.ru/2.1/?apikey=${memorizedYandexAPIKey}&lang=ru_RU`);

  usePhoneFromSearchParams();
  useIsMobileFromSearchParams();

  return (
    <div className="main">
      {isMobile && !isTitleHidden && (
        <div
          className="close-btn"
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (window.closeHandler) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              window.closeHandler.postMessage('');
            }
          }}
        >
          &times;
        </div>
      )}
      <Routes>
        <Route index element={<Register />} key="Register" />
        <Route path="/addresses" element={<Addresses phone={phone} isMobile={isMobile} />} key="addresses" />
        <Route path="/no-route" element={<NoRoute />} key="RegisterPage" />
        <Route path="/payment" element={<Payment />} key="PaymentPage" />
        <Route path="/tours" element={<Tours />} key="ToursPage" />
        <Route path="/subscriptions/:uuid" element={<SubscriptionInfo />} key="SubscriptionPage" />
        <Route path="*" element={<NotFoundPage />} key="DefaultPage" />
      </Routes>
    </div>
  );
};

export default App;
