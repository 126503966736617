import { subscriptionKind } from '@/shuttle/constants';

export const purchaseAmount = (
  subscriptionKindValue: string,
  passengerCountValue: number,
  onlineOneTripPaymentAmountValue: number,
  weeklyPaymentAmountValue: number,
  monthlyPaymentAmountValue: number
) => {
  switch (subscriptionKindValue) {
    case subscriptionKind.DAY:
      return passengerCountValue * onlineOneTripPaymentAmountValue;
    case subscriptionKind.WEEK:
      return passengerCountValue * weeklyPaymentAmountValue;
    case subscriptionKind.MONTH:
      return passengerCountValue * monthlyPaymentAmountValue;
    default:
      return 0;
  }
};
