import { Btn } from '@/components';
import checkmark from '@/projects/school/assets/img/checkmark.svg';
import { Head, Title } from '@/school/components';
import { tLogPageView } from '@/utils/tracking';
import { useEffect } from 'react';

import styles from './index.module.scss';

export const Finish = () => {
  useEffect(() => tLogPageView(), []);

  return (
    <div>
      <div className={styles.wrapper}>
        <Head small={true} center={true} />
        <div className={styles.checkmark}>
          <img src={checkmark} alt="checkmark" />
        </div>
        <Title marginTop={24}>Мы приняли вашу заявку!</Title>
        <div className={styles.text}>
          После рассмотрения заявки мы вернемся с обратной связью по указанному номеру телефона
        </div>
      </div>
      <div className={styles.questionText}>Если остались вопросы:</div>
      <Btn
        color="black"
        isFixedBottom={true}
        onClick={() => {
          window.open('http://wa.me/+77774756272');
        }}
      >
        Написать в службу поддержки
      </Btn>
    </div>
  );
};
