import { axiosInstance } from '@/utils/AxiosInstance';
import type { GeoJsonObject } from 'geojson';
import L from 'leaflet';
import 'leaflet-routing-machine';
import { FC, useEffect, useMemo, useState } from 'react';
import { GeoJSON, Marker, Polyline, Popup } from 'react-leaflet';

import { Map } from '@/components';
import { cityCenterCoords } from '@/shuttle/constants';

import endPointPinSrc from '@/assets/shuttle/icons/end_pin.svg';
import ridePointPinSrc from '@/assets/shuttle/icons/ride_pin.svg';
import startPointPinSrc from '@/assets/shuttle/icons/start_pin.svg';
import './index.module.scss';

// Icons.
const startPointIcon = new L.Icon({
  iconUrl: startPointPinSrc,
  iconSize: [40, 46],
  iconAnchor: [20, 46],
  popupAnchor: [0, -37],
});

const ridePointIcon = new L.Icon({
  iconUrl: ridePointPinSrc,
  iconSize: [40, 46],
  iconAnchor: [20, 46],
  popupAnchor: [0, -37],
});

const endPointIcon = new L.Icon({
  iconUrl: endPointPinSrc,
  iconSize: [40, 46],
  iconAnchor: [20, 46],
  popupAnchor: [0, -37],
});
// Options.
const blackDashedLineOptions = { color: '#000', weight: 1, dashArray: '3, 5' };
const mainOptions = { color: '#FD3E5F', weight: 4 };

type TourMapProps = {
  data: tourDataType;
};

const getUrl = (points: string): string => {
  return (
    `https://osrm.uvu.kz/route/v1/foot/${points}?alternatives=false&steps=false` +
    '&geometries=geojson&overview=full&annotations=false'
  );
};

interface ORSMRoute {
  geometry: GeoJsonObject;
}

interface OSRMResponse {
  code: 'Ok' | 'InvalidUrl' | 'InvalidService' | 'InvalidQuery';
  routes: ORSMRoute[];
}

const TourMap: FC<TourMapProps> = ({ data }) => {
  const [geoData, setGeoData] = useState<GeoJsonObject | null>(null);
  const tourData = useMemo(() => data, [data]);
  const points = useMemo(
    () => [
      L.latLng(tourData.from_address.location.lat, tourData.from_address.location.lng),
      L.latLng(tourData.tour.pickup_bus_stop.location.lat, tourData.tour.pickup_bus_stop.location.lng),
      L.latLng(tourData.tour.dropoff_bus_stop.location.lat, tourData.tour.dropoff_bus_stop.location.lng),
      L.latLng(tourData.to_address.location.lat, tourData.to_address.location.lng),
    ],
    [tourData]
  );

  const bounds = L.latLngBounds(points);
  const center = bounds.getCenter();

  useEffect(() => {
    const fetchData = async () => {
      const locations = tourData.tour.route_bus_stops!.map((bs: busStopType) => [bs.location.lng, bs.location.lat]) as [
        number,
        number
      ][];
      const pickupBusStopLocation = tourData.tour.pickup_bus_stop.location;
      const dropoffBusStopLocation = tourData.tour.dropoff_bus_stop.location;
      const pointsString = (
        locations.length > 0
          ? locations
          : [
              [pickupBusStopLocation.lng, pickupBusStopLocation.lat],
              [dropoffBusStopLocation.lng, dropoffBusStopLocation.lat],
            ]
      )
        .map((point) => point.join(','))
        .join(';');
      const url = getUrl(pointsString);
      const response = await axiosInstance.get<OSRMResponse>(url);
      if (response.code !== 'Ok' || !response.routes || response.routes.length === 0) {
        console.log('No route returned');
        return null;
      }
      setGeoData(response.routes[0].geometry);
    };

    fetchData().catch(console.error);
    return () => setGeoData(null);
  }, [tourData.tour]);

  return (
    <Map center={center || cityCenterCoords}>
      {/*Start point.*/}
      <Marker position={[tourData.from_address.location.lat, tourData.from_address.location.lng]} icon={startPointIcon}>
        <Popup>{tourData.from_address.name}</Popup>
      </Marker>
      {/* Ride start point. */}
      <Marker
        position={[tourData.tour.pickup_bus_stop.location.lat, tourData.tour.pickup_bus_stop.location.lng]}
        icon={ridePointIcon}
      >
        <Popup>{tourData.tour.pickup_bus_stop.name}</Popup>
      </Marker>
      {/* Ride end point. */}
      <Marker
        position={[tourData.tour.dropoff_bus_stop.location.lat, tourData.tour.dropoff_bus_stop.location.lng]}
        icon={ridePointIcon}
      >
        <Popup>{tourData.tour.dropoff_bus_stop.name}</Popup>
      </Marker>
      {/* End point. */}
      <Marker position={[tourData.to_address.location.lat, tourData.to_address.location.lng]} icon={endPointIcon}>
        <Popup>{tourData.to_address.name}</Popup>
      </Marker>
      {/* Start point - ride start point. */}
      <Polyline
        key={`${tourData.from_address.location.lat} + ${tourData.from_address.location.lng}_${tourData.tour.id}`}
        pathOptions={blackDashedLineOptions}
        positions={[
          [tourData.from_address.location.lat, tourData.from_address.location.lng],
          [tourData.tour.pickup_bus_stop.location.lat, tourData.tour.pickup_bus_stop.location.lng],
        ]}
      />
      {/* Ride end point - end point. */}
      <Polyline
        key={`${tourData.tour.dropoff_bus_stop.location.lat} + ${tourData.tour.dropoff_bus_stop.location.lng}_${tourData.tour.id}`}
        pathOptions={blackDashedLineOptions}
        positions={[
          [tourData.tour.dropoff_bus_stop.location.lat, tourData.tour.dropoff_bus_stop.location.lng],
          [tourData.to_address.location.lat, tourData.to_address.location.lng],
        ]}
      />
      {geoData && <GeoJSON data={geoData} style={() => mainOptions} />}
    </Map>
  );
};

export default TourMap;
