import React from 'react';

import checkmark from '@/assets/shuttle/icons/checkmark.svg';

import styles from './index.module.scss';

const FormSuccess: React.FC = () => {
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.checkmark}>
          <img src={checkmark} alt="checkmark" />
        </div>
        <div className={styles.title}>Мы приняли вашу заявку!</div>
        <div className={styles.text}>
          После рассмотрения заявки мы вернемся с обратной связью по указанному номеру телефона
        </div>
      </div>
    </>
  );
};

export default FormSuccess;
