import { setToaster } from '@/store/slices/toasterSlice';
import { RootState } from '@/travel_shuttle/store/store';
import { axiosInstance } from '@/utils/AxiosInstance';
import { tLogCompleteRegistration } from '@/utils/tracking';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

type customerData = {
  phone: string;
  fio?: string;
};

export type customerInfoResponse = {
  id: number;
  phone: string;
  first_name: string;
  last_name: string;
};

export const getOrCreateCustomerAsync = createAsyncThunk(
  'register/getOrCreateCustomerAsync',
  async (data: customerData, { dispatch }): Promise<customerInfoResponse> => {
    const [firstName, lastName] = data.fio ? data.fio.split(' ') : ['', ''];
    try {
      return await axiosInstance
        .post<customerInfoResponse>('/customers', { first_name: firstName, last_name: lastName, ...data })
        .then((data) => data);
    } catch (err) {
      dispatch(
        setToaster({
          type: 'warning',
          message: 'Произошла ошибка при регистрации.',
          isVisible: true,
        })
      );
      throw err;
    }
  }
);

export interface registerState {
  customer_id: number | null;
}

const initialState: registerState = {
  customer_id: null,
};

export const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    setCustomerId: (state: registerState, action: PayloadAction<number>) => {
      state.customer_id = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrCreateCustomerAsync.fulfilled, (state, action: PayloadAction<customerInfoResponse>) => {
      state.customer_id = action.payload.id;
      tLogCompleteRegistration(action.payload.id);
    });
  },
});

export const subscriptionSelector = (state: RootState) => state.registerReducer;

export default registerSlice.reducer;
