import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React, { FC, MutableRefObject, Ref, forwardRef } from 'react';
import { MapContainer, MapContainerProps, TileLayer } from 'react-leaflet';
import './SmoothWheelZoom.js';

export type MapProps = {
  center: L.LatLngExpression;
  children?: React.ReactNode | React.ReactNode[] | null;
  scrollWheelZoom?: L.Zoom;
  onMapReady?: () => void;
  ref?: Ref<L.Map | null>;
};

const Map: FC<MapProps & MapContainerProps> = forwardRef(({ center, children, onMapReady }, ref) => {
  return (
    <MapContainer
      center={center}
      zoom={12}
      maxZoom={18}
      minZoom={6}
      scrollWheelZoom={false}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore:next-line
      smoothWheelZoom={true}
      ref={ref as MutableRefObject<L.Map | null>}
      whenReady={onMapReady}
    >
      <TileLayer
        attribution='<a href="https://2gis.ru">2gis</a>'
        subdomains="01234"
        url="https://tile{s}.maps.2gis.com/tiles?x={x}&y={y}&z={z}&v=1&ts=online_hd"
      />
      {children}
    </MapContainer>
  );
});

export default Map;
