import { Root } from '@/components/shared/pages/Root';
import { useIsMobileFromSearchParams, usePhoneFromSearchParams } from '@/hooks/useSearchParams';

import { Home } from '@/school/pages/Home';
import { SchoolRoot } from '@/school/pages/School';
import { Finish } from '@/school/pages/School/Finish';
import { Form } from '@/school/pages/School/Form';
import { Subscription } from '@/school/pages/School/Subscription';
import { Welcome } from '@/school/pages/School/Welcome';
import { Form as MirasForm } from '@/school/pages/_schools/Miras/Form';

function App() {
  // Update values from query params;
  usePhoneFromSearchParams();
  useIsMobileFromSearchParams();
  return (
    <Root
      routes={[
        {
          path: '/',
          element: <Home />,
        },
        {
          path: 'miras/form',
          element: <MirasForm />,
        },
        {
          path: ':school',
          element: <SchoolRoot />,
          children: [
            {
              path: '',
              element: <Welcome />,
            },
            {
              path: 'form',
              element: <Form />,
            },
            {
              path: 'subscription',
              element: <Subscription />,
            },
            {
              path: 'finish',
              element: <Finish />,
            },
          ],
        },
      ]}
    />
  );
}

export default App;
