import { TitleBlock } from '@/components';
import { useAppSelector } from '@/shuttle/app/hooks';
import { toursSelector } from '@/shuttle/store/slices/toursSlice';
import { mainSelector } from '@/store/slices/mainSlice';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';

import TourBlock from '@/components/shared/molecules/TourBlock';

const TourListController: FC = () => {
  const { isMobile } = useAppSelector(mainSelector);
  const { tourList } = useAppSelector(toursSelector);
  const navigate = useNavigate();

  useEffect(() => {
    if (!Object.keys(tourList).length) {
      navigate('/addresses');
    }
  }, [tourList, navigate]);

  return (
    <div className={styles.main}>
      <div className={styles.titleWrapper}>
        <TitleBlock navigate={navigate} isMobile={isMobile} withBackBtn={true} withCloseBtn={false}>
          Выбери маршрут
        </TitleBlock>
      </div>
      {Object.keys(tourList).map((key: string) => (
        // Show the first tour of each array.
        <TourBlock data={tourList[key][0]} key={tourList[key][0].tour.id} />
      ))}
    </div>
  );
};

export default TourListController;
