// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import '@/index.css';
import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import App from './App';
import { getStore } from './store/getStore';

Sentry.init({
  dsn: 'https://ad704208a3e6480eb8eef7a127876973@o576058.ingest.sentry.io/4504342315204608',
  integrations: [
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay(),
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.5,
  tracesSampleRate: 0.1,
  // Don't send any events from localhost.
  enabled: import.meta.env.PROD,
  environment: import.meta.env.VITE_APP_ACTIVE_ENV,
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Provider store={getStore()}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
