import classNames from 'classnames';
import React from 'react';
import styles from './index.module.scss';

type PreLoaderProps = {
  showBackground?: boolean;
  showText?: boolean;
};

const PreLoader: React.FC<PreLoaderProps> = ({ showBackground = false, showText = false }) => {
  return (
    <div className={classNames(styles.wrapper, { [styles.whiteBg]: showBackground })}>
      <div className={styles.central}>
        {showText && <div className={styles.centralText}>Загрузка...</div>}
        <div className={styles.ldsRoller}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default PreLoader;
