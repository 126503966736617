import { FC } from 'react';

import styles from './MobileAppPreview.module.scss';
import mockup from './assets/mockup.png';

const MobileAppPreview: FC = () => {
  return (
    <div className={styles.info}>
      <div className={styles.infoWrapper}>
        <div className={styles.infoText}>После регистрации сможешь управлять поездками в удобном приложении</div>
        <div className={styles.infoImg}>
          <img src={mockup} alt="mockup" />
        </div>
      </div>
    </div>
  );
};

export default MobileAppPreview;
