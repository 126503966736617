import { getDateDayFullMonthString, getDateWeekdayString } from '@/utils/date';
import { FC } from 'react';
import Tour from '../Tour';
import styles from './index.module.scss';

type TourSlideProps = {
  data: tourDataType;
  showDate?: boolean;
};

const TourSlide: FC<TourSlideProps> = ({ data, showDate = false }) => {
  const date = new Date(data.tour.date);
  return (
    <div className={styles.wrapper}>
      {showDate && (
        <div className={styles.date}>
          {getDateWeekdayString(date)}, {getDateDayFullMonthString(date)}
        </div>
      )}

      <Tour data={data} />
    </div>
  );
};

export default TourSlide;
