import { appNames } from '@/constants';
import { store as fakeShuttleStore } from '@/projects/fake_shuttle/store/store';
import { store as schoolStore } from '@/projects/school/store/store';
import { store as shuttleStore } from '@/projects/shuttle/store/store';
import { store as travelStore } from '@/projects/travel_shuttle/store/store';

export function getStore() {
  // Sometimes we need to use additional slices.
  const app_name = import.meta.env.VITE_APP_NAME;
  if (app_name === appNames.SCHOOL) {
    return schoolStore;
  } else if (app_name === appNames.FAKE) {
    return fakeShuttleStore;
  } else if (app_name === appNames.TRAVEL) {
    return travelStore;
  }
  // By default, use the common store.
  return shuttleStore;
}
