import mainReducer from '@/store/slices/mainSlice';
import toasterReducer from '@/store/slices/toasterSlice';
import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import addressReducer from './slices/addressSlice';
import funnelReducer from './slices/funnelSlice';
import paymentReducer from './slices/paymentSlice';
import registerReducer from './slices/registerSlice';
import shuttleReducer from './slices/shuttleSlice';
import subscriptionReducer from './slices/subscriptionSlice';
import toursReducer from './slices/toursSlice';

export const store = configureStore({
  reducer: {
    mainReducer,
    toasterReducer,
    shuttleReducer,
    addressReducer,
    funnelReducer,
    toursReducer,
    paymentReducer,
    registerReducer,
    subscriptionReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: import.meta.env.VITE_APP_ACTIVE_ENV !== 'production',
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
