import logo from '@/assets/shuttle/img/logo.png';
import { FC } from 'react';
import styles from './index.module.scss';

type DriverProps = {
  route_name: string;
  photo_url: string;
  name: string;
  car_name: string;
  car_number_plate: string;
};

const Driver: FC<DriverProps> = ({ route_name, photo_url, name, car_name, car_number_plate }) => {
  return (
    <div className={styles.driver}>
      <div className={styles.driverRoute}>Маршрут: {route_name}</div>
      <div className={styles.driverPhoto}>
        {photo_url ? (
          <img src={photo_url} alt="driver" className={styles.driverPhotoImg} />
        ) : (
          <img src={logo} alt="driver" className={styles.driverPhotoImg} />
        )}
      </div>
      <div className={styles.driverText}>
        <div className={styles.driverTextName}>{name}</div>
        <div className={styles.driverTextCar}>{car_name}</div>
        <div className={styles.driverTextNumberPlate}>{car_number_plate}</div>
      </div>
    </div>
  );
};

export default Driver;
