import { axiosInstance } from '@/utils/AxiosInstance';
import { createAsyncThunk } from '@reduxjs/toolkit';

export type TourResponseFormData = {
  id: number;
};

export const updateTourResponseAsync = createAsyncThunk(
  'tourresponses/UpdateTourResponseFormAsync',
  async (data: TourResponseFormData) => {
    return await axiosInstance
      .patch<TourResponseFormData>(`/search-responses/${data.id}`, { is_selected: true })
      .then((data): TourResponseFormData => data)
      .catch((exc) => {
        console.log(exc);
      });
  }
);
