import PaymentController from '@/fake_shuttle/components/containers/PaymentController';
import { tLogPageView } from '@/utils/tracking';
import { FC, useEffect } from 'react';

const Payment: FC = () => {
  useEffect(() => {
    tLogPageView();
  }, []);

  return <PaymentController />;
};

export default Payment;
