import classNames from 'classnames';
import React, { useState } from 'react';
import styles from './index.module.scss';

type OptionType = { value: unknown; label: string };
export type OptionsType = Array<OptionType>;
export type ValueType = OptionType | null;

type SelectProps = {
  options: OptionsType | null;
  value: ValueType;
  onChange: (value: ValueType) => void;
  id?: string;
  className?: string;
  inputClassName?: string;
  menuClassName?: string;
  onBlur?: () => void;
};

const Select: React.FC<SelectProps> = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className={classNames(styles.wrapper, props.className)} onClick={() => setIsMenuOpen(!isMenuOpen)}>
      <input
        id={props.id}
        className={classNames(styles.input, props.inputClassName)}
        onBlur={() => {
          setTimeout(() => {
            setIsMenuOpen(false);
          }, 300);
        }}
        value={props.value ? props.value.label : ''}
        disabled={true}
      />
      <span className={classNames(styles.triangle, { [styles.active]: isMenuOpen })} />
      {isMenuOpen && props.options && props.options.length > 0 && (
        <div className={classNames(styles.menu, props.menuClassName)}>
          {props.options.map((option, index) => (
            <div key={index} className={styles.option} onClick={() => props.onChange(option)}>
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Select;
