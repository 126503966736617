/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { addressType } from '@/shuttle/pages/Adresses';
import * as Sentry from '@sentry/react';

// From Ташкент to Ключевский район, Алтайский край
const bounds = [
  [41.311017, 69.278981],
  [52.187125, 79.61209],
];

type getAddressNameResult = {
  status?: string;
  message?: string;
  data: AddressType;
};

export function getCoords(obj: AddressType): getAddressNameResult {
  return new Promise((resolve, reject) => {
    const copiedObj = { ...obj };

    ymaps
      .geocode(obj.name, {
        boundedBy: bounds,
        strictBounds: true,
      })
      .then(function (res: ymaps.IGeocodeResult) {
        const firstGeoObject = res.geoObjects.get(0);
        if (firstGeoObject) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          const coords = firstGeoObject.geometry?.getCoordinates();

          resolve({
            status: 'success',
            data: {
              ...copiedObj,
              location: {
                lat: coords[0],
                lng: coords[1],
              },
            },
          });
        } else {
          console.log('Координаты не получены из за границ bounds.');
          reject({
            status: 'error',
            message: 'Не удалось получить координаты точки.',
          });
        }
      })
      .catch((e) => {
        reject({
          status: 'error',
          message: 'Не удалось получить координаты точки.',
        });
        Sentry.withScope((scope) => {
          scope.setExtra('data', {
            data: [obj.name],
          });
          Sentry.captureException(e);
        });
      });
  });
}

export function getAddressName(obj: addressType): getAddressNameResult {
  return new Promise((resolve, reject) => {
    const copiedObj = { ...obj };

    ymaps
      .geocode([obj.location.lat, obj.location.lng], {
        boundedBy: bounds,
        strictBounds: true,
      })
      .then(function (res) {
        const firstGeoObject = res.geoObjects.get(0);

        if (firstGeoObject) {
          resolve({
            status: 'success',
            data: {
              ...copiedObj,
              name: firstGeoObject.getAddressLine().split(', ').splice(1).join(', '),
            },
          });
        } else {
          console.log('Название адреса не получено из за границ bounds.');
          reject({
            status: 'error',
            message: 'Название адреса не получено из за границ bounds.',
          });
        }
      })
      .catch((e) => {
        console.log(e);
        reject({
          status: 'error',
          message: 'Не удалось получить название адреса.',
        });
        Sentry.withScope((scope) => {
          scope.setExtra('data', {
            data: [obj.location?.lat, obj.location?.lng],
          });
          Sentry.captureException(e);
        });
      });
  });
}
