import checkmark from '@/assets/shuttle/icons/checkmark.svg';
import { Btn } from '@/components';
import { tLogPageView } from '@/utils/tracking';
import React, { useEffect } from 'react';
import styles from './index.module.scss';

const NoRoutePage: React.FC = () => {
  useEffect(() => {
    tLogPageView();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.main}>
      <div className={styles.checkMark}>
        <img src={checkmark} alt="CheckMark" className={styles.checkMarkImg} />
      </div>
      <div className={styles.text}>
        ой, подходящего маршрута еще нет 🙁
        <span className={styles.textSmall}>заполни форму, мы создадим маршрут для тебя</span>
      </div>
      <Btn
        color="black"
        onClick={() => {
          window.location.href = 'https://forms.gle/TUJMPV3SnwbrNaU3A';
        }}
        customClassName={styles.btn}
      >
        Заполнить форму
      </Btn>
    </div>
  );
};

export default NoRoutePage;
