import { FC } from 'react';

import styles from './index.module.scss';

const Oferta: FC = () => {
  return (
    <div className={styles.main}>
      <p className={styles.center}>
        <strong>Договор публичной оферты</strong>
      </p>
      <p>
        Настоящее Лицензионное соглашение (далее &ndash; &laquo;
        <strong>
          <strong>Соглашение</strong>
        </strong>
        &raquo;) является публичной офертой, которое устанавливает условия использования программы
        &laquo;UVU&nbsp;shuttle&raquo; и адресовано от&nbsp;
        <strong>
          <strong>ТОО SHUTTLE BUS</strong>
        </strong>
        <strong>
          <strong>&nbsp;</strong>
        </strong>
        (далее &ndash;
        <strong>
          <strong>&nbsp;&laquo;Лицензиар</strong>
        </strong>
        &raquo; и/или
        <strong>
          <strong>&nbsp;&laquo;</strong>
        </strong>
        <strong>
          <strong>TOO </strong>
        </strong>
        <strong>
          <strong>SHUTTLE BUS</strong>
        </strong>
        <strong>
          <strong>&raquo;</strong>
        </strong>
        ) к любому физическому лицу, отвечающему требованиям настоящего Соглашения (далее &ndash; &laquo;
        <strong>
          <strong>Пользователь</strong>
        </strong>
        &raquo;),. Перед использованием Веб-интерфейса и/или мобильного приложения обязательно внимательное прочтение
        настоящего Соглашения и безоговорочное принятие всех его условий.
        <br />
        <br />
      </p>
      <ol>
        <li className={styles.center}>
          <strong>
            <strong> Термины и определения</strong>
          </strong>
        </li>
      </ol>
      <p>
        В настоящем Соглашении используются следующие термины, имеющие определенное ниже значение. В случае, если в
        Соглашении используются термины и понятия, не определенные в настоящем разделе, они имеют значения, которые
        обычно придаются им в соответствующей отрасли/сфере.
        <br />
        <strong>
          <strong>1) Оферта</strong>
        </strong>
        &nbsp;&ndash; публичное предложение на использование Сервиса ТОО SHUTTLE BUS в соответствии пунктом 5 статьи 395
        Гражданского кодекса Республики Казахстан.
        <br />
        <strong>
          <strong>2) Акцепт</strong>
        </strong>
        &nbsp;&ndash; безоговорочное принятие Пользователем условий Соглашения в полном объеме путем Регистрации и
        оплаты Услуг ТОО SHUTTLE BUS.
        <br />
        <strong>
          <strong>3) Водитель&nbsp;</strong>
        </strong>
        &ndash; лицо, управляющее транспортным средством, и заключившее с ТОО SHUTTLE BUS Лицензионное соглашение на
        использование Программы &laquo;ТОО SHUTTLE BUS&raquo;;
        <br />
        <strong>
          <strong>4) Устройство</strong>
        </strong>
        &nbsp;&ndash; электронное устройство, подключенное к сети Интернет, работающее на базе операционных систем iOS
        или Android, дающее возможность Регистрации/ авторизации, а также (в зависимости от функциональных возможностей̆
        Устройства) возможность пользования Веб-интерфейсем и/или мобильным приложением. Устройства включают, без
        ограничений, компьютеры (в том числе, ноутбук/лэптоп, десктоп, компьютеры Macintosh), мобильные телефоны
        (включая смартфоны и коммуникаторы), КПК (включая устройства типа &laquo;планшет&raquo;).
        <br />
        <strong>
          <strong>5)</strong>
        </strong>
        &nbsp;
        <strong>
          <strong>Сервис</strong>
        </strong>
        &nbsp;&ndash; услуга ТОО SHUTTLE BUS, предоставляемая зарегистрированным и авторизованным Пользователям с
        помощью Веб-интерфейса и/или мобильного приложения, по поиску услуг водителей по организации перевозки
        пассажиров.
        <br />
        <strong>
          <strong>6) Регистрация Пользователя</strong>
        </strong>
        &nbsp;&ndash; совершение Пользователем, заинтересованным в использовании Сервиса, и ТОО SHUTTLE BUS
        определенного набора действий, зафиксированного в настоящем Соглашении.
        <br />
        <strong>
          <strong>7) Авторизация Пользователя</strong>
        </strong>
        &nbsp;&ndash; осуществление определенного набора действий, зафиксированных в настоящем Соглашении, а также в
        Веб-интерфейсе и/или мобильном приложении, в целях идентификации зарегистрированного Пользователя для
        последующего пользования Сервисом посредством Веб-интерфейса и/или мобильного приложения.
        <br />
        <strong>
          <strong>8) Служба поддержки</strong>
        </strong>
        &nbsp;&ndash; структурное подразделение ТОО SHUTTLE BUS, осуществляющее консультационную поддержку Пользователя,
        и его информирование в процессе предоставления использования Сервисов Веб-интерфейса и/или мобильного
        приложения, а также в процессе предоставления ТОО SHUTTLE BUS Сервиса. Поддержка и информирование осуществляются
        при обращении Пользователя в Службу поддержки по телефону, указанному в Веб-интерфейсе и/или мобильном
        приложении.
        <br />
        <strong>
          <strong>9) Веб-интерфейс&nbsp;</strong>
        </strong>
        - совокупность программных средств, обеспечивающих публикацию для всеобщего обозрения информации и данных,
        объединенных общим целевым назначением, посредством технических средств. Веб-интерфейс доступен Пользователю по
        уникальному электронному адресу или его буквенному обозначению. Под Веб-интерфейсом в Соглашении понимается
        сайт, расположенный в сети Интернет по адресу minibus.uvu.kz/oferta
        <br />
        <br />
        Термины, употребляемые по тексту настоящего Соглашения в единственном числе, могут подразумевать множественное
        число, и наоборот.
        <br />
        <br />
      </p>
      <ol start={2}>
        <li className={styles.center}>
          <strong>
            <strong> Предмет Соглашения и общие положения</strong>
          </strong>
        </li>
      </ol>
      <p>
        2.1. В соответствии с настоящим Соглашением ТОО SHUTTLE BUS предоставляет Пользователю отзывное неисключительное
        право пользования Сервисами Веб-интерфейса и/или мобильного приложения. Исключительное право на Программу
        принадлежит ТОО SHUTTLE BUS.
        <br />
        2.2. Программа предоставляет Пользователю возможность воспользоваться услугами Водителей по перевозке
        пассажиров&nbsp;до места работы&nbsp;и обратно, и осуществить поиск таких предложений по заданным Пользователем
        параметрам.
        <br />
        2.3. ТОО SHUTTLE BUS безвозмездно, на условиях простой (неисключительной) лицензии, предоставляет Пользователю
        непередаваемое право использования Программы на территории Республики Казахстан следующими способами:
        <br />
        2.3.1. Использование Программы допускается только способами, предусмотренными настоящим Соглашением.
        Пользователь гарантирует, что не будет использовать Программу с нарушением требований действующего
        законодательства, условий настоящего Соглашения, в целях причинения вреда ТОО SHUTTLE BUS, другим Пользователям
        и/или третьим лицам.
        <br />
        2.3.2. Применять Программу по прямому функциональному назначению, в целях чего произвести её копирование и
        установку и/или воспроизведение) на мобильное устройство Пользователя.
        <br />
        2.4. Программа должна использоваться под наименованием: &laquo;UvU&nbsp;Shuttle&raquo;. Пользователь не вправе
        изменять и/или удалять наименование Программы, знак охраны авторского права (copyright notice) или иные указания
        на ТОО SHUTTLE BUS.
        <br />
        2.5. Копируя Программу, устанавливая её на свое мобильное устройство или используя Программу любым образом,
        Пользователь выражает свое полное и безоговорочное согласие со всеми условиями настоящего Соглашения.
        <br />
        2.6. Использование Сервисов Веб-интерфейса и/или мобильного приложения допускается только способами,
        предусмотренными настоящим Соглашением.
        <br />
        2.7. Пользователь гарантирует, что не будет использовать Сервисы Веб-интерфейса и/или мобильного приложения с
        нарушением требований действующего законодательства, условий настоящего Соглашения, в целях причинения вреда TOO
        SHUTTLE BUS и/или третьим лицам.
        <br />
        2.8. О наличии ошибок или неполадок, возникших в процессе использования Сервисов Веб-интерфейса и/или мобильного
        приложения Пользователь сообщает в Службу поддержки.
        <br />
        2.9. Сервисы Веб-интерфейса и/или мобильного приложения могут быть использованы Пользователем для получения
        информации о ТОО SHUTTLE BUS, его партнерах, ознакомления с услугами, оказываемыми ТОО SHUTTLE BUS, его
        партнерами.
        <br />
        2.10. В зависимости от региона Пользователя все или некоторые функции Сервиса могут быть недоступны или
        ограничены. Применение любых технических и программных методов обхода данных ограничений запрещено.
        <br />
        2.11. Сервис предоставляется Пользователю для личного некоммерческого использования в соответствии с Правилами
        перевозки пассажиров (Приложение №1 к настоящему Соглашению).
        <br />
        2.12. ТОО SHUTTLE BUS не оказывает услуги по перевозке детей, услуги информационно-диспетчерской службы и иные
        услуги.
        <br />
        2.13. ТОО SHUTTLE BUS не несет ответственности за содержание и/или актуальность информации, предоставляемой
        партнерами Сервиса, включая информацию о стоимости услуг водителей, а также об их наличии в данный момент.
        Взаимодействие Пользователя с водителями по вопросам приобретения услуг осуществляется Пользователем
        самостоятельно (без участия ТОО SHUTTLE BUS) в соответствии с установленными водителями тарифами. ТОО SHUTTLE
        BUS не несет ответственности за финансовые и любые другие операции, совершаемые Пользователем и водителями, а
        также за любые последствия приобретения Пользователем услуг водителей без использования Сервиса ТОО SHUTTLE BUS.
        <br />
        2.14. ТОО SHUTTLE BUS оставляет за собой право на свое усмотрение ограничить доступ Пользователя к Сервису (или
        к определенным его функциям) с использованием его учетной записи или полностью заблокировать учетную запись
        Пользователя при нарушении настоящего Соглашения, либо применить к Пользователю иные меры с целью соблюдения
        требований законодательства или прав и законных интересов третьих лиц.
        <br />
        2.15. В случае отмены и/или отказа Пользователя от услуг по перевозке позднее 24 часов до запланированного
        времени поездки, деньги Пользователю не возвращаются.
        <br />
        2.16. Оплата производится на счет ТОО SHUTTLE BUS банковскими картами и/или иными безналичными способами, в том
        числе электронными деньгами. Услуги предоставляются при условии 100% предоплаты, тестовые периоды не
        предоставляются. Безналичная оплата осуществляется Пользователем с участием уполномоченного оператора по приему
        платежей или оператора электронных денежных средств и регулируется правилами международных платежных систем,
        банков (в том числе банка-эмитента Привязанной карты) и других участников расчетов.
        <br />
        2.17. Для получения услуги ТОО SHUTTLE BUS Пользователь по своему желанию выбирает тарифный план, проводит
        регистрацию путем предоставления персональных данных и производит оплату. За правильность платежей
        ответственность лежит на Пользователе.
        <br />
        2.18. Пользователь имеет право отслеживать по GPS-навигатору маршрут следования во время оказания Услуг.
        <br />
        2.19. Пользователь обязан соблюдать масочный режим и выполнять иные меры предосторожности во время оказания
        Услуг.
        <br />
        2.20. ТОО SHUTTLE BUS имеет право блокировать доступ к серверу в следующих случаях:
        <br />
        1) при получении распоряжений государственных органов РК;
        <br />
        2) при нарушении авторских и смежных прав;
        <br />
        3) при мотивированном обращении третьих лиц при нарушении их прав;
        <br />
        4) при обнаружении запрещенной законодательством информации, размещенной Пользователем.
        <br />
        2.21. Следующее приложение являются неотъемлемой частью Соглашения:
        <br />
        1) Приложение №1 &laquo;Правила перевозки пассажиров&raquo;.
        <br />
        <br />
      </p>
      <ol start={3}>
        <li className={styles.center}>
          <strong>
            <strong> Регистрация и авторизация Пользователя</strong>
          </strong>
        </li>
      </ol>
      <p>
        3.1. Использование Сервисов Веб-интерфейса и/или мобильного приложения возможно только при условии Регистрации
        Пользователя в Веб-интерфейсе и/или мобильном приложении и авторизации соответственно в Веб-интерфейсе и/или
        мобильном приложении в соответствии с установленной настоящим Соглашением.
        <br />
        3.2. Для Регистрации Пользователя в Веб-интерфейсе и/или мобильном приложении, Пользователь самостоятельно
        совершает загрузку (скачивание) на Устройство мобильного приложения ТОО SHUTTLE BUS с использованием магазинов
        приложений AppStore (itunes.apple.com) и/или Google Play (play.google.com) в сети Интернет. Для Регистрации
        Пользователя в Веб-интерфейсе, Пользователь осуществляет вход в Веб-интерфейс.
        <br />
        3.3. Для Регистрации Пользователя в Веб-интерфейсе и/или мобильном приложении Пользователь заполняет анкету,
        предоставляя требуемую в этой анкете информацию.
        <br />
        3.4. Пользователь не может завершить Регистрацию, не приняв условия настоящего Соглашения путем проставления
        галочки в чек-боксе &laquo;Я согласен с условиями и принимаю Пользовательское соглашение&raquo; (в
        Веб-интерфейсе) или нажатия кнопки &laquo;Принять&raquo; в мобильном приложении.
        <br />
        3.5. Перед выполнением действий, указанных в п. 3.3, 3.4 настоящего Соглашения Пользователь вводит номер своего
        мобильного телефона, адрес электронной почты и sms-пароль для входа в личный кабинет. В последующем, при
        авторизации в Веб-интерфейсе и/или мобильном приложении логином для доступа к личному кабинету Пользователя
        является номер мобильного телефона или адрес электронной почты, указанный Пользователем при Регистрации.
        <br />
        3.6. В случае если Пользователь при Регистрации предоставляет недостоверную информацию, либо если у ТОО SHUTTLE
        BUS есть основания полагать, что представленная Пользователем информация недостоверна, ТОО SHUTTLE BUS вправе по
        своему усмотрению в одностороннем порядке заблокировать доступ Пользователя к использованию Сервисов
        Веб-интерфейса и/или мобильного приложения.
        <br />
        3.7. По окончании Регистрации авторизация Пользователя, подразумевающая возможность использования Веб-интерфейса
        и/или мобильного приложения и пользования Сервисом в полном объеме, осуществляется путем введения логина и
        пароля в личном кабинете в Веб-интерфейсе и/или мобильном приложении.
        <br />
        3.8. Пользователь обязуется сохранять в тайне логин и пароль, а также иные данные, посредством которых может
        быть получен доступ к Сервисам Веб-интерфейса и/или мобильного приложения от имени Пользователя. В случае утраты
        логина, пароля, или при наличии оснований полагать, что этими данными (а также иными данными) завладело третье
        лицо, Пользователь обязан с использованием контактных данных, указанных им при Регистрации, подать ТОО SHUTTLE
        BUS запрос (путем обращения в Службу поддержки) о блокировании доступа в личный кабинет, после чего
        сгенерировать другой пароль, а по согласованию с ТОО SHUTTLE BUS, также логин. Запрос о блокировании доступа в
        личный кабинет рассматривается ТОО SHUTTLE BUS в течение 3 (трех) часов с момента направления запроса. Все
        риски, вытекающие из невыполнения данного обязательства Пользователем, лежат на Пользователе.
        <br />
        3.9. По окончании Регистрации все вызовы в Службу поддержки осуществляются Пользователем с номера мобильного
        телефона, указанного при заполнении анкеты, либо иного номера телефона при условии предоставления Пользователем
        дополнительных данных по запросу сотрудника Службы поддержки.
        <br />
        3.10. Пользователь может изменить номер мобильного телефона, указанный им при Регистрации, через Личный кабинет.
        <br />
        <br />
      </p>
      <ol start={4}>
        <li className={styles.center}>
          <strong>
            <strong> Заявления Пользователя</strong>
          </strong>
        </li>
      </ol>
      <p>
        <br />
        4.1. Принимая условия настоящего Соглашения, Пользователь заявляет, что он:
        <br />- прочитал условия Соглашения, осознал значение и смысл указанного документа, согласен с его содержанием и
        принимает его без каких-либо оговорок, условий и изъятий и обязуется выполнять установленные им требования,
        нести ответственность за неисполнение/ненадлежащее исполнение изложенных в нем требований и условий, а также
        понимает все последствия своих действий по Регистрации, пользованию Сервисами Веб-интерфейса и/или мобильного
        приложения, Сервисом;
        <br />- предоставил ТОО SHUTTLE BUS при Регистрации полные, действительные и достоверные данные (в том числе,
        персональные данные);
        <br />- соответствует всем требованиям, предъявляемым к нему настоящим Соглашением;
        <br />- согласен с тем, что размер нанесенных ТОО SHUTTLE BUS убытков, возникших в результате нарушения
        Пользователем любых его гарантий и обязательств по настоящему Соглашению, определяется единолично ТОО SHUTTLE
        BUS и по его усмотрению, и безусловно соглашается возмещать ТОО SHUTTLE BUS такие убытки;
        <br />- согласен с тем, что настоящее Соглашение может быть изменено ТОО SHUTTLE BUS без каких-либо уведомлений
        со Стороны ТОО SHUTTLE BUS, и обязуется самостоятельно отслеживать соответствующие изменения настоящего
        Соглашения. Новая редакция настоящего Соглашения или изменения вступают в силу с момента их размещения в
        Веб-интерфейсе и/или мобильном приложении;
        <br />- согласен на получение от ТОО SHUTTLE BUS любых уведомлений, сообщений, информации и рассылок в любом
        формате, при условии соответствия их содержания требованиям действующего законодательства Республики Казахстан;
        <br />- признает юридическую силу за документами, направленными ТОО SHUTTLE BUS посредством Веб-интерфейса и/или
        мобильного приложения как за собственноручно подписанными документами, за действиями, совершенными посредством
        Веб-интерфейса и/или мобильного приложения.
        <br />- признает что в стоимость подписки включены все государственные праздники и каникулы.
        <br />
        <br />
        4.2. Ознакомление с условиями настоящего Соглашения должно быть произведено Пользователем до момента
        проставления галочки в чек-боксе &laquo;Я согласен с условиями и принимаю Пользовательское соглашение&raquo; (в
        Веб-интерфейсе) или нажатия кнопки &laquo;Принять&raquo; в мобильном приложении. Пользователь, своевременно не
        ознакомившийся с условиями настоящего Соглашения, принимает на себя все риски связанных с этим неблагоприятных
        последствий.
        <br />
        <br />
        <br />
      </p>
      <ol start={5}>
        <li className={styles.center}>
          <strong>
            <strong> Согласие Пользователя</strong>
          </strong>
        </li>
      </ol>
      <p>
        5.1. Пользователь дает свое согласие на обработку и трансграничную передачу ТОО SHUTTLE BUS предоставленных им
        (а также полученных от любых третьих лиц) своих персональных данных: ФИО, дата, месяц и год рождения, адрес
        регистрации, количество и ФИО детей, адреса доставки, контактные данные, в том числе (но не ограничиваясь) номер
        телефона, адрес электронной почты, также иные персональные данные.
        <br />
        5.2. Согласие на обработку персональных данных дается Пользователем для целей заключения и исполнения настоящего
        Соглашения, оказания услуг, участия в проводимых ТОО SHUTTLE BUS акциях, опросах, исследованиях (включая, но не
        ограничиваясь проведением опросов, исследований посредством электронной, телефонное и сотовой связи), принятия
        решений или совершения иных действий, порождающих юридические последствия в отношении Пользователя или других
        лиц, представления Пользователю информации об оказываемых ТОО SHUTTLE BUS услугах, предоставлением ТОО SHUTTLE
        BUS консультационных услуг, и распространяется на всю информацию, указанную в пункте 5.1 Соглашения.
        <br />
        5.3. Обработка персональных данных Пользователя осуществляется ТОО SHUTTLE BUS в объеме, который необходим для
        достижения каждой из вышеперечисленных целей, следующими возможными способами: сбор, запись (в том числе на
        электронные носители), систематизация, накопление, хранение, составление перечней, маркировка, уточнение
        (обновление, изменение), извлечение, использование, передача (распространение, предоставление, доступ),
        обезличивание, блокирование, удаление, уничтожение, трансграничная передача персональных данных, получение
        изображения путем фотографирования, а также осуществление любых иных действий с персональными данными
        Пользователя с учетом действующего законодательства Республики Казахстан. Обработка осуществляется как с помощью
        средств автоматизации, так и без использования таких средств.
        <br />
        5.4. Пользователь подтверждает, что данное им согласие на обработку персональных данных действует бессрочно с
        момента их предоставления Пользователем ТОО SHUTTLE BUS.
        <br />
        5.5. Пользователь вправе отозвать свое согласие на обработку персональных данных путем направления
        соответствующего письменного уведомления ТОО SHUTTLE BUS не менее чем за 60 (шестьдесят) календарных дней до
        момента отзыва согласия, при этом Пользователь признает и понимает, что доступ к пользованию Сервисами
        Веб-интерфейса и/или мобильного приложения, Сервису не будет предоставляться ТОО SHUTTLE BUS с того момента,
        когда ТОО SHUTTLE BUS лишилось возможности обрабатывать персональные данные Пользователя.
        <br />
        5.6. Пользователь признает и подтверждает, что в случае необходимости предоставления персональных данных для
        достижения указанных выше целей третьему лицу (в том числе уполномоченным государственным органам), а равно как
        при привлечении третьих лиц к оказанию услуг, передачи ТОО SHUTTLE BUS принадлежащих ему функций и полномочий
        иному лицу (уступке, в том числе для рассмотрения возможности уступки и принятию решения об уступке, взысканию
        задолженности и др.), ТОО SHUTTLE BUS вправе без получения дополнительного согласия со стороны Пользователя в
        необходимом объеме раскрывать для совершения вышеуказанных действий информацию о Пользователе лично (включая
        персональные данные) таким третьим лицам, их агентам и иным уполномоченным ими лицам, а также представлять таким
        лицам соответствующие документы, содержащие такую информацию, с соблюдением требований законодательства
        Республики Казахстан.
        <br />
        5.7. Пользователь признает и подтверждает, что ТОО SHUTTLE BUS на основании заключенного с третьими лицами
        соглашения о конфиденциальности вправе передавать им персональные данные и осуществлять трансграничную передачу
        персональных данных Пользователя исключительно в целях, предусмотренных настоящим Соглашением.
        <br />
        5.8. Пользователь признает и подтверждает, что в случае рассмотрения ТОО SHUTTLE BUS вопросов уступки прав по
        настоящему Соглашению, согласие на передачу его персональных данных и данных о его соглашениях третьим лицам
        &ndash; потенциальным цессионариям для решения ими вопроса о заключении с ТОО SHUTTLE BUS договора цессии
        &ndash; считается предоставленным Пользователем ТОО SHUTTLE BUS. В случае уступки прав по настоящему Соглашению
        ТОО SHUTTLE BUS вправе не направлять Пользователю уведомления об этом. Настоящим Пользователь соглашается не
        получать каких-либо уведомлений о совершенной ТОО SHUTTLE BUS уступке в соответствии с настоящим пунктом
        Соглашения.
        <br />
        5.9. Пользователь дает свое согласие на получение от ТОО SHUTTLE BUS рекламных сообщений, содержащих рекламу
        товаров и услуг, реализуемых ТОО SHUTTLE BUS и/или ее партнерами и/или иными третьими лицами, на адрес
        электронной почты и номер мобильного телефона (включая привязанные к нему аккаунты в мессенджерах WhatsApp,
        Viber, Telegram и пр.), указанные Пользователем в процессе Регистрации, а также иные адреса электронной почты и
        номера мобильных телефонов (включая привязанные к ним аккаунты в мессенджерах WhatsApp, Viber, Telegram и пр.),
        сообщенные Пользователем ТОО SHUTTLE BUS в соответствии с настоящим Соглашением. В случае, если Пользователь
        обращается к TOO SHUTTLE BUS с требованием прекратить распространение в его адрес рекламных сообщений, ТОО
        SHUTTLE BUS обязуется немедленно прекратить такое распространение в отношении Пользователя, обратившегося с
        соответствующим требованием.
        <br />
        5.10. Пользователь дает свое согласие на осуществление ТОО SHUTTLE BUS записи контактов (разговоров)
        Пользователя со Службой поддержки и предоставление такой записи третьим лицам.
        <br />
        <br />
      </p>
      <ol start={6}>
        <li className={styles.center}>
          <strong>
            <strong> Гарантии, обязанности и ответственность Пользователя</strong>
          </strong>
        </li>
      </ol>
      <p>
        6.1. Пользователь гарантирует, что он будет использовать Веб-интерфейс и/или мобильное приложение только в
        соответствии с условиями настоящего Соглашения.
        <br />
        6.2. Пользователь гарантирует, что не будет осуществлять Регистрацию от имени другого лица и/или передавать
        регистрационные данные третьим лицам.
        <br />
        6.3. Пользователь гарантирует, что он является держателем всех банковских карт, привязанных им к Веб-интерфейсу
        и/или мобильному приложению, Сервису.
        <br />
        6.4. Пользователь понимает и соглашается, что для надлежащего использования Сервисов Веб-интерфейса и/или
        мобильного приложения, он сам должен обеспечить надлежащую скорость интернет-подключения Устройств. Пользователь
        перед использованием Сервисов Веб-интерфейса и/или мобильного приложения должен самостоятельно убедиться в том,
        что его Устройство обладает необходимыми для этого характеристиками. Все вопросы приобретения прав доступа в
        Интернет, покупки и наладки для этого соответствующих Устройств и программных продуктов решаются Пользователем
        самостоятельно и за свой счет и не подпадают под действие Соглашения.
        <br />
        6.5. Пользователь несет ответственность за все действия, осуществляемые им в Веб-интерфейсе и/или мобильном
        приложении.
        <br />
        6.6. Пользователь не должен допускать использование любым третьим лицом логина, пароля Пользователя. ТОО SHUTTLE
        BUS не отвечает за возможный ущерб, потерю или порчу данных, которые могут произойти из-за нарушения
        Пользователем данного положения. Пользователь обязуется принимать надлежащие меры для защиты и сохранения в
        тайне информации, используемой для авторизации в Веб-интерфейсе и/или мобильном приложении, включая логин и
        пароль, от несанкционированного использования другими лицами и незамедлительно сообщать ТОО SHUTTLE BUS
        соответствующую информацию в случае обнаружения факта такого использования. Пользователь несет все риски
        неблагоприятных последствий, связанных с отсутствием такого сообщения.
        <br />
        6.7. Пользователь обязуется самостоятельно отслеживать изменения условий настоящего Соглашения в Веб-интерфейсе
        и/или мобильном приложении.
        <br />
        6.8. Пользователь обязуется не использовать никакие технологии и не предпринимать никаких действий, которые
        могут нанести вред Веб-интерфейсу и/или мобильному приложению, интересам и имуществу ТОО SHUTTLE BUS.
        <br />
        6.9. Пользователь обязуется соблюдать иные положения действующего законодательства Республики Казахстан,
        положения настоящего Соглашения и законные требования ТОО SHUTTLE BUS.
        <br />
        6.10. В случае, если в связи с нарушением Пользователем условий настоящего Соглашения ТОО SHUTTLE BUS понесла
        убытки, в том числе в результате предъявления к ТОО SHUTTLE BUS третьими лицами претензий, исков, Пользователь
        обязуется урегулировать такие претензии и иски собственными силами и за свой счет, и возместить ТОО SHUTTLE BUS,
        вызванные несоблюдением Пользователем условий настоящего Соглашения убытки в полном объеме.
        <br />
        6.11. За неисполнение или ненадлежащее исполнение своих обязательств по настоящему Соглашению, Пользователь
        несет ответственность в соответствии с действующим законодательством Республики Казахстан, настоящим
        Соглашением.
        <br />
        6.12. Пользователь обязуется в случае смены номера мобильного телефона, и иных данных, указанных при
        Регистрации, сообщить об этом ТОО SHUTTLE BUS. Пользователь несет все риски неблагоприятных последствий,
        связанных с отсутствием такого сообщения.
        <br />
        <br />
      </p>
      <ol start={7}>
        <li className={styles.center}>
          <strong>
            <strong> Гарантии и ответственность ТОО SHUTTLE BUS</strong>
          </strong>
        </li>
      </ol>
      <p>
        7.1. ТОО SHUTTLE BUS предоставляет Пользователю Сервисы Веб-интерфейса и/или мобильного приложения, а также
        любую информацию, содержащуюся в Веб-интерфейсе и/или мобильном приложении &laquo;как есть&raquo; (&laquo;as
        is&raquo;) без гарантий любого рода. Это означает, среди прочего, что ТОО SHUTTLE BUS:
        <br />
        1) не несет ответственности за скорость и бесперебойную работу Веб-интерфейса и/или мобильного приложения, их
        совместимость с программным обеспечением и операционными системами Устройств Пользователя;
        <br />
        2) не несет ответственности за отсутствие ошибок и/или вирусов при работе Веб-интерфейса и/или мобильного
        приложения, прерывание каналов связи и выход из строя серверной инфраструктуры на уровне магистральных каналов
        связи, центров обмена данными, вычислительных центров, а также линий связи регионального и местного значения,
        неисполнение либо ненадлежащее исполнение своих обязательств вследствие сбоев в телекоммуникационных и/или
        энергетических сетях, а также недобросовестных действий третьих лиц, направленных на несанкционированный доступ
        и/или выведение из строя Веб-интерфейса и/или мобильного приложения. Пользователь соглашается и подтверждает,
        что использует Веб-интерфейс и/или мобильное приложение исключительно на свой собственный риск.
        <br />
        3) не несет ответственности за надлежащее функционирование Устройства Пользователя;
        <br />
        4) не несет ответственности за несоответствие Сервисов Веб-интерфейса и/или мобильного приложения, Сервиса
        ожиданиям Пользователя;
        <br />
        5) не несет ответственности за действия Пользователя в Веб-интерфейсе и/или мобильном приложении, и за
        последствия использования Пользователем Веб-интерфейса и/или мобильного приложения, Сервиса.
        <br />
        7.2. В случае если Устройство Пользователя не поддерживает отображение и работу, загрузку, отображение и работу
        Веб-интерфейса и/или мобильного приложения, каких-либо Сервисов Веб-интерфейса и/или мобильного приложения, TOO
        SHUTTLE BUS не гарантирует предоставление доступа к соответствующим ресурсам и возможность их
        использования/полноценного использования.
        <br />
        7.3. ТОО SHUTTLE BUS не несет ответственности за временную неработоспособность платежных систем, обеспечивающую
        прием и перевод платежей Пользователя, вызванную не зависящими от ТОО SHUTTLE BUS причинами, а также
        обстоятельствами непреодолимой силы.
        <br />
        7.4. ТОО SHUTTLE BUS не несет ответственности за нарушение условий Соглашения, если такое нарушение вызвано
        действием обстоятельств непреодолимой силы (форс-мажор), включая среди прочих: действия и решения органов
        государственной власти и/или местного самоуправления, пожар, наводнение, землетрясение, другие стихийные
        действия, отсутствие электроэнергии и/или сбои работы компьютерной сети, забастовки, гражданские волнения,
        чрезвычайные положения, беспорядки, пандемии, эпидемии любые иные обстоятельства, которые могут повлиять на
        выполнение ТОО SHUTTLE BUS условий настоящего Соглашения.
        <br />
        7.5. ТОО SHUTTLE BUS не несет ответственности за неисполнение либо ненадлежащее исполнение своих обязательств,
        если это вызвано действиями/бездействием Пользователя, находящимися вне контроля ТОО SHUTTLE BUS, в том числе в
        результате совершения ошибочных действий/бездействия Пользователя.
        <br />
        7.6. В Веб-интерфейсе и/или мобильном приложении могут быть представлены продукты и/или услуги, предлагаемые
        Пользователю на платной и/или бесплатной основе третьими лицами. ТОО SHUTTLE BUS не является участником
        отношений по их предоставлению/оказанию, и ответственности перед Пользователем и/или третьими лицами за их
        представление/оказание не несет.
        <br />
        7.7. Веб-интерфейс и/или мобильное приложение могут содержать ссылки на сайты, приложения и иные ресурсы третьих
        лиц. Указанные сайты, приложения и иные ресурсы третьих лиц и их материалы не проверяются ТОО SHUTTLE BUS на
        соответствие требованиям законодательства Республики Казахстан и ответственность за их содержание ТОО SHUTTLE
        BUS не несет.
        <br />
        7.8. Ссылка на любой сайт, приложение, ресурс, продукт или услугу, любую информацию коммерческого или
        некоммерческого свойства, размещенная в Веб-интерфейсе и/или мобильном приложении, не является одобрением или
        рекомендацией вышеуказанного со стороны ТОО SHUTTLE BUS. Если Пользователь решит перейти к сайтам, приложениям,
        ресурсам, продуктам, услугам третьих лиц или использовать программы третьих лиц, то делает это на свой риск.
        <br />
        7.9. Действия любых третьих лиц, а также действия платежных систем, операторов связи не являются действиями TOO
        SHUTTLE BUS, и ответственности за них ТОО SHUTTLE BUS не несет.
        <br />
        7.10. ТОО SHUTTLE BUS не несет ответственность за размещение Веб-интерфейса и/или мобильного приложения, ссылок
        на него в любых не санкционированных (не подтвержденных) ТОО SHUTTLE BUS источниках (контрафактное приложение).
        В случае использования Пользователем контрафактного приложения, ТОО SHUTTLE BUS не несет перед ним какой-либо
        ответственности в связи с таким использованием.
        <br />
        7.11. ТОО SHUTTLE BUS гарантирует добросовестную и законную обработку персональных данных Пользователя в
        соответствии с предусмотренными в настоящем Соглашении целями.
        <br />
        7.12. ТОО SHUTTLE BUS гарантирует своевременное обновление данных Пользователя в случае предоставления им
        обновленных данных.
        <br />
        7.13. ТОО SHUTTLE BUS несёт ответственность только за прямой действительный ущерб, причинённый вследствие
        виновного невыполнения обязательств ТОО SHUTTLE BUS, в пределах суммы платежей за пользование Сервисом (не
        включая возмещение расходов, штрафы и убытки), уплаченных Пользователем ТОО SHUTTLE BUS за весь срок,
        предшествующий предъявлению соответствующего требования, но не более 12 (двенадцати) месяцев.
        <br />
        7.14. ТОО SHUTTLE BUS не несет ответственность за использование Веб-интерфейса и/или мобильного приложения с
        Устройства Пользователя третьими лицами, в связи с чем все действия, совершенные с Устройства Пользователя,
        считаются действиями Пользователя. В случае, если какое-либо лицо получает доступ к Сервисам Веб-интерфейса
        и/или мобильного приложения и возможность их использования от имени Пользователя, Пользователь обязан
        незамедлительно сообщить об этом, обратившись в Службу поддержки. В противном случае все действия, совершенные
        от имени Пользователя с использованием Веб-интерфейса и/или мобильного приложения, будут расцениваться как
        действия, совершенные непосредственно Пользователем.
        <br />
        <br />
      </p>
      <ol start={8}>
        <li className={styles.center}>
          <strong>
            <strong> Срок действия Соглашения. Порядок изменения и отказа от Соглашения</strong>
          </strong>
        </li>
      </ol>
      <p>
        8.1. Пользователь принимает настоящее Соглашение путем совершения действий, указанных в п. 3.4 настоящего
        Соглашения. Дата совершения Пользователем указанных действий является датой вступления в силу настоящего
        Соглашения. Настоящее Соглашение действует с даты вступления в силу в течение неопределенного срока.
        <br />
        8.2. ТОО SHUTTLE BUS имеет право в любое время вносить изменения в условия настоящего Соглашения. Изменения в
        условия Соглашения осуществляются путем внесения изменений в существующую редакцию Соглашения, либо создания
        новой редакции Соглашения, и становятся обязательными для ТОО SHUTTLE BUS и Пользователя (вступают в силу) с
        даты размещения ТОО SHUTTLE BUS новой редакции Соглашения либо изменений, внесенных в Соглашение, в
        Веб-интерфейсе и/или мобильном приложении. В случае, если Пользователь не согласен с изменениями, он вправе
        отказаться от исполнения настоящего Соглашения в порядке, указанном ниже, что не освобождает Пользователя от
        исполнения своих обязательств, возникших до прекращения отношений по Соглашению, при этом Пользователь обязуется
        прекратить использовать Сервисы Веб-интерфейса и/или мобильного приложения, а также прекратить использование
        Сервиса.
        <br />
        8.3. В случае, если Пользователь не отказался от исполнения Соглашения (не направил заявление (уведомление) о
        расторжении (п. 8.5.), считается, что Пользователь прочитал, понял, согласен соблюдать Соглашение в новой
        редакции или изменения к нему и безоговорочно принимает указанную новую редакцию Соглашения или изменения к нему
        со дня вступления их в силу. Пользователь на периодической основе самостоятельно осуществляет мониторинг
        Веб-интерфейса и/или мобильного приложения на предмет изменения условий Соглашения. Риск несоблюдения данного
        требования в полной мере ложится на Пользователя.
        <br />
        8.4. ТОО SHUTTLE BUS и Пользователь вправе отказаться от исполнения настоящего Соглашения, уведомив об этом
        другую Сторону не позднее, чем за 2 (два) календарных дня до предполагаемой даты прекращения Соглашения. При
        этом инициатор расторжения Соглашения, обязуется исполнить все свои обязательства, вытекающие из Соглашения, не
        позднее даты прекращения Соглашения.
        <br />
        8.5. Отказ от Соглашения по инициативе Пользователя осуществляется в сроки, предусмотренные настоящим
        Соглашением, на основании собственноручно подписанного заявления (уведомления) Пользователя, переданного
        Пользователем ТОО SHUTTLE BUS (лично, курьером, по почте), либо, при отсутствии возражений со стороны ТОО
        SHUTTLE BUS, Пользователь может направить скан подписанного им заявления (уведомления) по электронной почте на
        адрес
        <br />
        ______________.
        <br />
        8.6. ТОО SHUTTLE BUS вправе в одностороннем внесудебном порядке отказаться от исполнения Соглашения в случае
        нарушения Пользователем любых его обязательств, заявлений и гарантий, предусмотренных Соглашением. В этом случае
        Соглашение считается прекращенным в момент отправки Пользователю уведомления посредством мобильного приложения
        (направления push-уведомления) или отправки sms-сообщений, или посредством электронной почты.
        <br />
        8.7. В части неисполненных обязательств Сторон прекращенное по любым основаниям Соглашение продолжает
        действовать вплоть до исполнения указанных обязательств в полном объеме.
        <br />
        <br />
      </p>
      <ol start={9}>
        <li className={styles.center}>
          <strong>
            <strong> Разрешение споров и подсудность</strong>
          </strong>
        </li>
      </ol>
      <p>
        9.1. Пользователь признает, что Веб-интерфейс и/или мобильное приложение являются достаточными для обеспечения
        надлежащей работы при приеме, передаче, обработке и хранении информации, а также для защиты информации от
        несанкционированного доступа, подтверждения подлинности и авторства электронных документов, отправленных с их
        использованием, а также для разбора конфликтных ситуаций. Пользователь доверяет программному обеспечению
        Веб-интерфейса и/или мобильного приложения.
        <br />
        9.2. Веб-интерфейс и/или мобильное приложения являются интеллектуальной собственностью ТОО SHUTTLE BUS, любое
        использование допускается только на основании разрешения ТОО SHUTTLE BUS. Использование Веб-интерфейса и/или
        мобильного приложения без разрешения ТОО SHUTTLE BUS любыми способами и в целях иных, чем допускаются
        Соглашением, является незаконным и может повлечь за собой привлечение Пользователя к ответственности.
        <br />
        9.3. В части, не урегулированной настоящим Соглашением, отношения ТОО SHUTTLE BUS и Пользователя регулируются
        действующим законодательством Республики Казахстан.
        <br />
        9.4. ТОО SHUTTLE BUS вправе использовать факсимильное воспроизведение подписи уполномоченного лица ТОО SHUTTLE
        BUS (с помощью средств механического или иного копирования) в любых документах, сообщениях и уведомлениях.
        Стороны признают юридическую силу за такими документами.
        <br />
        9.5. По всем вопросам, связанным с использованием Сервисов Веб-интерфейса и/или мобильного приложения со всеми
        претензиями Пользователь может обращаться в Службу поддержки. При обращении с претензией к ТОО SHUTTLE BUS
        Пользователь должен предоставить документы, подтверждающие обоснованность претензии, а также указать свои
        данные, предоставленные им при Регистрации.
        <br />
        9.6. Пользователь, не принявший условия настоящего Соглашения, либо принявший их по ошибке, должен сообщить об
        этом ТОО SHUTTLE BUS и не вправе пользоваться сервисом Веб-интерфейса и/или мобильного приложения, а также
        Сервисом.
        <br />
        <br />
      </p>
      <ol start={10}>
        <li className={styles.center}>
          <strong>
            <strong> Банковские реквизиты ТОО SHUTTLE BUS</strong>
          </strong>
        </li>
      </ol>
      <p>
        <strong>
          <strong>И</strong>
        </strong>
        <strong>
          <strong>П</strong>
        </strong>
        <strong>
          <strong>&nbsp;&laquo;Erlik Bus&raquo;</strong>
        </strong>
      </p>
      <p>Казахстан, г. Алматы</p>
      <p>ул. Какимбека Салыкова д. 140 кв. (офис) 1</p>
      <p>ИИН&nbsp;991009300581</p>
      <p>ИИК&nbsp;KZ86722S000029502881</p>
      <p>АО&nbsp;"Kaspi Bank"</p>
      <p>БИК&nbsp;CASPKZKA</p>
      <p>Кбе 19</p>
      <p>&nbsp;</p>
      <p className={styles.center}>
        <strong>
          <strong>Приложение №1 к Лицензионному соглашению </strong>
        </strong>
      </p>
      <p>
        <strong>
          <strong>от 27 октября 2023 года</strong>
        </strong>
      </p>
      <p>
        <br />
      </p>
      <p className={styles.center}>
        <strong>
          <strong>Правила перевозки пассажиров</strong>
        </strong>
      </p>
      <ol>
        <li>
          У пассажира должен быть личный мобильный телефон, а у Пользователя должна быть электронная почта.
          <br />
          2. Пользователь должен проявлять уважение и вежливость к водителю и оператору, воздерживаясь от грубых или
          агрессивных действий и высказываний
          <br />
          3. Расписание и адреса пассажира фиксированы, изменения по расписанию рассматриваются в течение 7 рабочих
          дней.
          <br />
          4. Время поездки каждого пассажира может достигать 60 минут в зависимости от расстояния между конечными
          точками маршрута.
        </li>
        <li>
          В автомобиле запрещено присутствие любых посторонних людей, кроме сотрудников ТОО SHUTTLE BUS.
          <br />
          6. Если водитель приехал к Пользователю раньше назначенного времени, Пользователь может не торопиться и выйти
          ко времени, указанному в заявке.
          <br />
          7. Во время забора пассажировдля развозки, после 2-х минут ожидания на точке сбора водитель обязан позвонить
          авторизованному лицу и предупредить о том, что продолжит движение по маршруту.
          <br />
          8. Время ожидания у места забора для развозки обратно составляет 10 минут. По истечению времени водитель
          обязан связаться с пассажиром, сообщить, что время ожидания вышло и водитель вынужден ехать. <br />
          9. Водитель не несет ответственности за сохранность личных вещей пассажира.
          <br />
          10. Водителю запрещено покидать автомобиль в течение всего маршрута в целях обеспечения безопасной перевозки,
          поэтому водители не встречают и не провожают пассажира.
          <br />
          11. Перерасчет за отмены поездки предусмотрен в следующих случаях:
          <br />
          1) По форс-мажорным обстоятельствам;
          <br />
          2) По причине невозможности оказания перевозки водителем.
          <br />
          12. В случае опоздания водителя, Пользователь должен незамедлительно обратиться к менеджеру ТОО SHUTTLE BUS.
          <br />
          13. В случае отсутствия препятствий (шлагбаум и тд.) водители привозят пассажирамаксимально близко ко входу.
        </li>
      </ol>
      <p>&nbsp;</p>
    </div>
  );
};

export default Oferta;
