import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/fake_shuttle/app/hooks';
import { funnelSelector, setSlug } from '@/fake_shuttle/store/slices/funnelSlice';

export function useFunnelFromSearchParams() {
  const { slug } = useAppSelector(funnelSelector);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const campaignQueryParam = searchParams.get('campaign');
    if (campaignQueryParam && slug !== campaignQueryParam) {
      dispatch(setSlug(campaignQueryParam));
    }
  }, [slug, searchParams, dispatch]);

  return slug;
}
