import { setToaster } from '@/store/slices/toasterSlice';
import { axiosFakeAPIInstance } from '@/utils/AxiosInstance';
import { createAsyncThunk } from '@reduxjs/toolkit';

export type shuttleFormData = {
  pickup_address: AddressType;
  dropoff_address: AddressType;
  phone: string;
  campaign?: string;
};

export const createShuttleFormAsync = createAsyncThunk(
  'shuttleForms/CreateShuttleFormAsync',
  async (data: shuttleFormData, { dispatch }) => {
    return await axiosFakeAPIInstance
      .post<shuttleFormData>('/shuttle-forms', data)
      .then((data): shuttleFormData => data)
      .catch((exc) => {
        dispatch(
          setToaster({
            type: 'warning',
            message: 'Не удалось отправить заявку. Попробуйте еще раз.',
            isVisible: true,
          })
        );
        throw exc;
      });
  }
);
