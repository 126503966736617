import { useAppSelector } from '@/hooks/hooks';
import { toasterSelector } from '@/store/slices/toasterSlice';
import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './toast.css';

const Toast: React.FC = () => {
  const toastSelector = useAppSelector(toasterSelector);

  useEffect(() => {
    if (toastSelector.isVisible) {
      toast(toastSelector.message, {
        type: toastSelector.type,
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [toastSelector]);

  return <ToastContainer />;
};

export default Toast;
