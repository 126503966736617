import * as Sentry from '@sentry/react';
import { useEffect, useMemo } from 'react';

function useScript(resourceUrl: string, onError: (error: Error) => void) {
  // Avoid re-rendering the script on every render.
  const memoizedResourceUrl = useMemo(() => resourceUrl, [resourceUrl]);
  useEffect(() => {
    if (window.ymaps === undefined) {
      const script = document.createElement('script');
      script.src = memoizedResourceUrl;
      script.type = 'text/javascript';

      script.onerror = (event) => {
        const error = new Error(`Failed to load ymaps script: ${memoizedResourceUrl}`);
        Sentry.captureException(error, {
          extra: {
            event,
          },
        });
        onError(error);
      };

      document.body.appendChild(script);
      console.log('ymaps created');

      return () => {
        document.body.removeChild(script);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedResourceUrl]);
}

export default useScript;
