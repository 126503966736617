import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/hooks/hooks';
import { mainSelector, setIsMobile, setPhone } from '@/store/slices/mainSlice';
import { isValidNumber, parseNumber } from '@/utils/phone';

export function usePhoneFromSearchParams() {
  const { phone } = useAppSelector(mainSelector);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    let phoneString;
    const localStoragePhone = localStorage.getItem('phone');
    const phoneFromSearchParams = searchParams.get('phone');
    if (phoneFromSearchParams) {
      phoneString = decodeURIComponent(phoneFromSearchParams);
    } else if (localStoragePhone) {
      phoneString = localStoragePhone;
    }

    if (!phoneString) return;
    if (!phoneString.startsWith('+')) {
      phoneString = '+' + phoneString;
    }

    const parsedPhoneValue = parseNumber(phoneString);
    if (!phone && isValidNumber(parsedPhoneValue)) {
      // TODO: Why is it called twice?
      dispatch(setPhone(parsedPhoneValue));
    }
  }, [phone, searchParams, dispatch]);

  return phone;
}

export function useIsMobileFromSearchParams() {
  const { isMobile } = useAppSelector(mainSelector);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const isMobileQueryParam = searchParams.get('is_mobile');
    const isMobileNewValue = String(isMobileQueryParam).toLowerCase() === 'true';
    if (isMobileQueryParam && isMobile !== isMobileNewValue) {
      dispatch(setIsMobile(isMobileNewValue));
    }
  }, [isMobile, searchParams, dispatch]);

  return isMobile;
}
