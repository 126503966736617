import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Register: FC = () => {
  const navigate = useNavigate();
  const nextPageURL = '/addresses';

  useEffect(() => {
    return navigate(nextPageURL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default Register;
