import back from '@/assets/shuttle/icons/back.svg';
import { Btn } from '@/components';
import Tour from '@/components/shared/molecules/TourSlide';
import TourMap from '@/components/shuttle/molecules/TourMap';
import { useAppSelector } from '@/shuttle/app/hooks';
import { toursSelector } from '@/shuttle/store/slices/toursSlice';
import { mainSelector } from '@/store/slices/mainSlice';
import { tLogCustomEvent } from '@/utils/tracking';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './index.module.scss';
import './paginations.scss';

import endPointPinSrc from '@/assets/shuttle/icons/end_pin.svg';
import ridePointPinSrc from '@/assets/shuttle/icons/ride_pin.svg';
import startPointPinSrc from '@/assets/shuttle/icons/start_pin.svg';
import { updateTourResponseAsync } from '@/projects/shuttle/store/slices/searchResponseSlice';
import { useAppDispatch } from '@/shuttle/app/hooks';

const TourSlidesController: FC = () => {
  const dispatch = useAppDispatch();
  const { isMobile } = useAppSelector(mainSelector);
  const { tours } = useAppSelector(toursSelector);
  const navigate = useNavigate();
  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(true);
  const [currentTour, setCurrentTour] = useState<tourDataType | null>(null);

  useEffect(() => {
    if (!tours.length) {
      navigate('/addresses');
    }
  }, [tours, navigate]);

  useEffect(() => {
    setCurrentTour(tours[0]);
  }, [tours]);

  return (
    <div className={styles.main}>
      {currentTour ? <TourMap data={currentTour} /> : null}
      <div className={styles.info}>
        <Swiper
          centeredSlides={true}
          slidesPerView={2}
          spaceBetween={290}
          pagination={{ clickable: true }}
          onSlideChange={(swiperInstance) => {
            tLogCustomEvent('scroll_routes');
            setCurrentTour(tours[swiperInstance.realIndex]);
          }}
          modules={[Pagination]}
        >
          {tours.map((tour) => (
            <SwiperSlide key={tour.tour.id}>
              <Tour data={tour} showDate={true} />
            </SwiperSlide>
          ))}
        </Swiper>
        {/* {currentTour && (
          <div className={styles.tourDate}>
            {getDateWeekdayString(new Date(currentTour.tour.date))},{' '}
            {getDateDayFullMonthString(new Date(currentTour.tour.date))}
          </div>
        )} */}
        {isPopupVisible && (
          <div className={styles.popup}>
            <div className={styles.popupCloseBtn} onClick={() => setIsPopupVisible(false)}>
              &times;
            </div>
            <div className={styles.popupItem}>
              <div className={styles.popupItemIcons}>
                <img src={startPointPinSrc} alt="Start Point" />
                <img src={endPointPinSrc} alt="End Point" className={styles.popupItemIconsSecond} />
              </div>
              <div>Введенные тобой адреса</div>
            </div>
            <div className={styles.popupItem}>
              <div className={styles.popupItemIcons}>
                <img src={ridePointPinSrc} alt="Ride Point" className={styles.popupItemIconsWithLeftMargin} />
              </div>
              <div>Остановка, где тебя будет ждать водитель в указанное время</div>
            </div>
          </div>
        )}
        <div className={styles.btns}>
          <Btn color="white" customClassName={styles.backBtn} onClick={() => navigate(-1)}>
            <img src={back} alt="back" />
          </Btn>
          <Btn
            color="black"
            disabled={!currentTour}
            onClick={() => {
              dispatch(updateTourResponseAsync({ id: currentTour!.tour.response_id }));
              navigate(
                `/payment?tour_id=${currentTour?.tour.id}` +
                  `&pickup_stop_id=${currentTour?.tour.pickup_bus_stop.id}` +
                  `&dropoff_stop_id=${currentTour?.tour.dropoff_bus_stop.id}` +
                  `&is_mobile=${isMobile}`
              );
            }}
          >
            Выбрать подписку
          </Btn>
        </div>
      </div>
    </div>
  );
};

export default TourSlidesController;
