import classNames from 'classnames';
import React from 'react';
import styles from './index.module.scss';

type InputProps = {
  label: string;
  value: string | number | readonly string[] | undefined;
  id: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  placeholder?: string;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
};

const Input: React.FC<InputProps> = (props) => {
  return (
    <div className={classNames(styles.wrap, props.className)}>
      <label className={classNames(styles.label, props.labelClassName)} htmlFor={props.id}>
        {props.label}
      </label>
      <input
        className={styles.input}
        type="text"
        id={props.id}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
      />
    </div>
  );
};

export default Input;
