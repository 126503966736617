import PhoneModal from '@/components/shuttle/modals/PhoneModal';
import { setPhone } from '@/store/slices/mainSlice';
import { setToaster } from '@/store/slices/toasterSlice';
import { useAppDispatch, useAppSelector } from '@/travel_shuttle/app/hooks';
import AddressesController from '@/travel_shuttle/components/containers/AddressesController';
import { addressSelector } from '@/travel_shuttle/store/slices/addressSlice';
import { searchToursAsync, toursSearchResponse } from '@/travel_shuttle/store/slices/toursSlice';
import { getCoords } from '@/utils/address';
import { _afLogEvent } from '@/utils/af';
import { tLogPageView } from '@/utils/tracking';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export type RouteBusStopType = {
  bus_stop_id: number;
  name: string;
  arrival_time: string;
};

export type lastSuccessfulScheduleInfoType = {
  tour_id: number;
  pickup_rbs: RouteBusStopType;
  dropoff_rbs: RouteBusStopType;
};

export type addressType = {
  name: string;
  location: {
    lat: number;
    lng: number;
  } | null;
};

export type AddressesProps = {
  phone: string;
  isMobile: boolean;
};

const Addresses: FC<AddressesProps> = ({ phone, isMobile }) => {
  const { pickupAddress, dropOffAddress, selectedDate } = useAppSelector(addressSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [modal, setModal] = useState<string | null>(null);

  //log events
  useEffect(() => {
    _afLogEvent('af_page_view', { location: '/' });
    tLogPageView();
  }, []);

  const CurrentModal = useMemo(() => {
    switch (modal) {
      case 'phone':
        return PhoneModal;
      default:
        return null;
    }
  }, [modal]);

  const handleSubmit = async (formPhone?: string) => {
    if (!pickupAddress || !dropOffAddress) return;

    let fromAddress = {
      name: pickupAddress.name,
      location: pickupAddress.location || null,
    };

    let toAddress = {
      name: dropOffAddress.name,
      location: dropOffAddress.location || null,
    };

    let showError = false;

    if (!fromAddress.location) {
      try {
        const response = await getCoords(fromAddress);

        if (response.status === 'success') {
          fromAddress = response.data;
        }
      } catch {
        showError = true;
      }
    }

    if (!toAddress.location) {
      try {
        const response = await getCoords(toAddress);

        if (response.status === 'success') {
          toAddress = response.data;
        }
      } catch {
        showError = true;
      }
    }

    if (showError) {
      dispatch(
        setToaster({
          isVisible: true,
          type: 'error',
          message: 'Произошла ошибка при получение координат адреса. Попробуйте еще раз',
        })
      );
      return;
    }

    dispatch(
      searchToursAsync({
        from_address: fromAddress,
        to_address: toAddress,
        phone: formPhone || phone,
        date: selectedDate?.value as string,
        domain: 'travel.uvu.kz',
      })
    )
      .unwrap()
      .then((resp) => {
        const response = resp as toursSearchResponse;
        if (response.tours.length) {
          navigate('/tours');
          dispatch(setPhone(formPhone || phone));
          // Tours not found.
        } else {
          navigate('/no-route');
        }
      });
  };

  return (
    <>
      <AddressesController
        phone={phone}
        isMobile={isMobile}
        onPhoneModalShow={() => setModal('phone')}
        modal={modal}
        onSubmit={handleSubmit}
      />
      {CurrentModal ? <CurrentModal onClose={() => setModal('')} onSubmit={(phone) => handleSubmit(phone)} /> : null}
    </>
  );
};

export default Addresses;
