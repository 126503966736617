import gradient from '@/assets/shuttle/img/gradient.png';
import { subscriptionKind } from '@/shuttle/constants';
import { FC, useMemo } from 'react';
import styles from './index.module.scss';

type BonusProps = {
  subscriptionKindValue: string;
  amount: number;
  passengerCountValue: number;
  onlineOneTripPaymentAmountValue: number;
  subscriptionPrice: number;
};

const Bonus: FC<BonusProps> = ({
  subscriptionKindValue,
  amount,
  passengerCountValue,
  onlineOneTripPaymentAmountValue,
  subscriptionPrice,
}) => {
  const calculatedBonus = useMemo(() => {
    if (amount <= 0) return 0;

    if (subscriptionKindValue === subscriptionKind.MONTH_SUB && amount > subscriptionPrice * passengerCountValue) {
      return subscriptionPrice * passengerCountValue;
    } else if (
      subscriptionKindValue === subscriptionKind.DAY &&
      amount > onlineOneTripPaymentAmountValue * passengerCountValue
    ) {
      return onlineOneTripPaymentAmountValue * passengerCountValue;
    }

    return amount;
  }, [amount, subscriptionKindValue, passengerCountValue, subscriptionPrice, onlineOneTripPaymentAmountValue]);

  return (
    <div className={styles.bonus}>
      {calculatedBonus} тг бонусов будет потрачено
      <img src={gradient} alt="gradient" className={styles.bonusImg} />
    </div>
  );
};

export default Bonus;
