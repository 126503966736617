import logo from '@/projects/school/assets/img/logo.svg';
import { schools } from '@/projects/school/constants';
import classNames from 'classnames';
import React from 'react';
import { useParams } from 'react-router-dom';
import styles from './index.module.scss';

type HeadProps = {
  small?: boolean;
  center?: boolean;
};

const Head: React.FC<HeadProps> = ({ center, small }) => {
  const params = useParams();
  const slug = params.school;

  if (!slug || !(slug in schools))
    return (
      <div
        className={classNames(styles.head, {
          [styles.center]: center,
          [styles.small]: small,
        })}
      >
        <div className={styles.logo}>
          <img src={logo} alt="UvU" />
        </div>
      </div>
    );

  const schoolLogo = schools[slug].logo;

  return (
    <div
      className={classNames(styles.head, {
        [styles.center]: center,
        [styles.small]: small,
      })}
    >
      <div className={styles.logo}>
        <img src={logo} alt="UvU" />
      </div>
      <div className={styles.x}>X</div>
      <div className={styles.schoolLogo}>
        <img src={schoolLogo} alt="School" />
      </div>
    </div>
  );
};

export default Head;
