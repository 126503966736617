import ellipse from '@/assets/shuttle/icons/ellipse.svg';
import { maxPassengerCount } from '@/shuttle/constants';
import { tLogCustomEvent } from '@/utils/tracking';
import classNames from 'classnames';
import { FC } from 'react';
import styles from './index.module.scss';

type PassengerCountProps = {
  passengerCount: number;
  updateCount: (value: number) => void;
};

const PassengerCount: FC<PassengerCountProps> = ({ passengerCount, updateCount }) => {
  return (
    <div className={styles.placeCount}>
      <div className={styles.placeCountText}>
        <img src={ellipse} alt="circle" className={styles.circle} /> Количество мест
      </div>
      <div className={styles.placeCountInput}>
        <div
          className={classNames(styles.placeCountBtn, styles.placeCountBtnDecrease)}
          onClick={() => {
            if (passengerCount === 1) return;
            updateCount(passengerCount - 1);
            tLogCustomEvent('change_seat_count', {
              value: passengerCount - 1,
            });
          }}
        />
        <div className={styles.placeCountNumber}>{passengerCount}</div>
        <div
          className={classNames(styles.placeCountBtn, styles.placeCountBtnIncrease)}
          onClick={() => {
            if (passengerCount === maxPassengerCount) return;
            updateCount(passengerCount + 1);
            tLogCustomEvent('change_seat_count', {
              value: passengerCount + 1,
            });
          }}
        />
      </div>
    </div>
  );
};

export default PassengerCount;
