// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const AF: any;

export function _afLogEvent(
  name: string,
  // eslint-disable-next-line @typescript-eslint/ban-types
  data: Object = {},
  revenue: number = 0,
  currency: string = 'KZT'
) {
  AF('pba', 'event', {
    eventType: 'EVENT',
    eventName: name,
    eventValue: data,
    eventRevenue: revenue,
    eventRevenueCurrency: currency,
  });
}
