import TourListController from '@/shuttle/components/containers/TourListController';
import { tLogPageView } from '@/utils/tracking';
import { useEffect } from 'react';

const TourList = () => {
  useEffect(() => {
    tLogPageView();
  }, []);

  return <TourListController />;
};

export default TourList;
