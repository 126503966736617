import classNames from 'classnames';
import React from 'react';
import styles from './index.module.scss';

type RadioSelectBlockType = {
  value: string | number | readonly string[] | undefined;
  onChange: (value: string) => void;
  children: React.ReactNode | React.ReactNode[];
  checked: boolean;
  id: string;
  name: string;
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
};

const RadioSelectBlock: React.FC<RadioSelectBlockType> = (props) => {
  return (
    <div className={classNames(styles.wrap, props.className)}>
      <input
        type="radio"
        name={props.name}
        id={props.id}
        className={classNames(styles.input, props.inputClassName)}
        value={props.value}
        checked={props.checked}
        onChange={(e) => props.onChange(e.target.value)}
      />
      <label htmlFor={props.id} className={classNames(styles.label, props.labelClassName)}>
        {props.children}
      </label>
    </div>
  );
};

export default RadioSelectBlock;
