import classNames from 'classnames';
import React from 'react';
import forwardArrow from './assets/icons/forward.svg';
import './index.scss';

type BtnProps = {
  type?: 'button' | 'reset' | 'submit';
  color: string;
  children: React.ReactNode | React.ReactNode[];
  customClassName?: string;
  onClick?: () => void;
  disabled?: boolean;
  withArrow?: boolean;
  isFixedBottom?: boolean;
};

const Btn: React.FC<BtnProps> = ({
  type = 'button',
  color,
  customClassName,
  children,
  onClick,
  disabled,
  withArrow,
  isFixedBottom,
}) => {
  return (
    <button
      type={type}
      className={classNames(`btn btn-${color}`, isFixedBottom ? `btn-fixed-bottom` : '', customClassName)}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
      {withArrow && (
        <span>
          <img src={forwardArrow} alt="forward" className="btn-arrow" />
        </span>
      )}
    </button>
  );
};

export default Btn;
