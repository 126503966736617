import { RootState } from '@/shuttle/store/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ShuttleState {
  isTitleHidden: boolean;
}

const initialState: ShuttleState = {
  isTitleHidden: false,
};

export const shuttleSlice = createSlice({
  name: 'shuttle',
  initialState,
  reducers: {
    setIsTitleHidden: (state, action: PayloadAction<boolean>) => {
      state.isTitleHidden = action.payload;
    },
  },
});

export const { setIsTitleHidden } = shuttleSlice.actions;

export const shuttleSelector = (state: RootState) => state.shuttleReducer;

export default shuttleSlice.reducer;
