import classNames from 'classnames';
import { FC, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './index.module.scss';

type LatestTripsProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  latestTrips: any[];
  isMobile: boolean;
};

const LatestTrips: FC<LatestTripsProps> = ({ latestTrips, isMobile }): ReactElement => {
  const navigate = useNavigate();

  return (
    <div className={styles.latestSchedules}>
      <div className={styles.latestSchedulesTitle}>Повтори последнюю поездку</div>
      <div className={styles.latestSchedulesList}>
        {latestTrips!.map((schedule, index) => (
          <div
            key={index}
            className={styles.latestSchedulesListItem}
            onClick={() => {
              navigate(
                `/payment?tour_id=${schedule.tour_id}` +
                  `&pickup_stop_id=${schedule.pickup_rbs.bus_stop_id}` +
                  `&dropoff_stop_id=${schedule.dropoff_rbs.bus_stop_id}` +
                  `&is_mobile=${isMobile}` +
                  `&is_title_hidden=false`
              );
            }}
          >
            <div className={styles.latestSchedulesListItemTimes}>
              <div className={styles.time}>{schedule.pickup_rbs.arrival_time}</div>
              <div className={styles.time}>{schedule.dropoff_rbs.arrival_time}</div>
            </div>
            <div className={styles.latestSchedulesListItemStick}>
              <div className={styles.latestSchedulesListItemStickSolid}></div>
            </div>
            <div className={styles.latestSchedulesListItemStops}>
              <div className={classNames(styles.point, styles.busStop)}>{schedule.pickup_rbs.name}</div>
              <div className={classNames(styles.point, styles.busStop)}>{schedule.dropoff_rbs.name}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LatestTrips;
