import ToursController from '@/projects/shuttle/components/containers/TourSlidesController';
import { tLogPageView } from '@/utils/tracking';
import { useEffect } from 'react';

const TourSlides = () => {
  useEffect(() => {
    tLogPageView();
  }, []);

  return <ToursController />;
};

export default TourSlides;
