import RegisterController from '@/fake_shuttle/components/containers/RegisterController';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

const Register: FC = () => {
  const { slug } = useParams();
  return <RegisterController slug={slug || ''} />;
};

export default Register;
