import { Badges } from '@/fake_shuttle/components/atoms';

import styles from './index.module.scss';

const DisabledFunnel = () => {
  return (
    <div>
      <div className={styles.title}>Ссылка уже устарела</div>
      <div className={styles.info}>Попробуйте найти маршрут в приложении</div>
      <Badges />
    </div>
  );
};

export default DisabledFunnel;
