import { wrapUseRoutes } from '@sentry/react';
import React from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';

const useSentryRoutes = wrapUseRoutes(useRoutes);

type RootProps = {
  routes: RouteObject[];
};

export const Root: React.FC<RootProps> = ({ routes }) => {
  return <div className="main">{useSentryRoutes(routes)}</div>;
};
