import { Toast } from '@/components';
import React, { FC } from 'react';

interface MainLayoutProps {
  children: React.ReactNode | React.ReactNode[];
}

const MainLayout: FC<MainLayoutProps> = ({ children }): React.ReactElement => {
  return (
    <>
      <Toast />
      {children}
    </>
  );
};

export default MainLayout;
