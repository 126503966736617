import { ValueType } from '@/components/shared/atoms/Select';
import { SubscriptionKind } from '@/projects/school/constants';
import { axiosInstance } from '@/utils/AxiosInstance';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';

interface SubscriptionForm {
  phone: string;
  school_id: number;
  home_address: AddressType | null;
  kind: string;
  child_grade: string | unknown;
  child_count: string | unknown;
  full_name: string;
  comment: string;
}

export const submitSubscriptionFormAsync = createAsyncThunk(
  'form/submitSubscriptionForm',
  async (data: SubscriptionForm) => {
    const response = await axiosInstance.post('/school-forms', data);
    return {
      phone: data.phone,
      data: response,
    };
  }
);

export interface FormState {
  isLoading: boolean;
  fullName: string;
  childCount: ValueType;
  grade: ValueType;
  homeAddress: AddressType | null;
  pickupBusStopID: number | null;
  comment: string;
  subscriptionKind: SubscriptionKind;
}

const initialState: FormState = {
  isLoading: false,
  fullName: '',
  grade: { value: 1, label: '1 класс' },
  childCount: { value: 1, label: '1' },
  homeAddress: null,
  pickupBusStopID: null,
  comment: '',
  subscriptionKind: SubscriptionKind.MONTHLY,
};

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setChildCount: (state: FormState, action: PayloadAction<ValueType>) => {
      state.childCount = action.payload;
    },
    setComment: (state: FormState, action: PayloadAction<string>) => {
      state.comment = action.payload;
    },
    setGrade: (state, action: PayloadAction<ValueType>) => {
      state.grade = action.payload;
    },
    setFullName: (state: FormState, action: PayloadAction<string>) => {
      state.fullName = action.payload;
    },
    setHomeAddress: (state: FormState, action: PayloadAction<AddressType>) => {
      state.homeAddress = action.payload;
    },
    setPickupBusStopID: (state: FormState, action: PayloadAction<number>) => {
      state.pickupBusStopID = action.payload;
    },
    setSubscriptionKind: (state: FormState, action: PayloadAction<SubscriptionKind>) => {
      state.subscriptionKind = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitSubscriptionFormAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(submitSubscriptionFormAsync.fulfilled, (state, action) => {
      state.isLoading = false;

      localStorage.setItem('phone', action.payload.phone);
    });
    builder.addCase(submitSubscriptionFormAsync.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const {
  setChildCount,
  setComment,
  setGrade,
  setFullName,
  setHomeAddress,
  setPickupBusStopID,
  setSubscriptionKind,
} = formSlice.actions;

export const formSelector = (state: RootState) => state.formReducer;

export default formSlice.reducer;
