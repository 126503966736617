import { RootState } from '@/store/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ToasterState {
  isVisible: boolean;
  type: 'error' | 'warning' | 'success';
  message: string;
}

const initialState: ToasterState = {
  isVisible: false,
  type: 'success',
  message: '',
};

type ToasterPayload = {
  isVisible: boolean;
  type: 'error' | 'warning' | 'success';
  message: string;
};

export const toasterSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setToaster: (state, action: PayloadAction<ToasterPayload>) => {
      state.isVisible = true;
      state.type = action.payload.type;
      state.message = action.payload.message;
    },
  },
});

export const { setToaster } = toasterSlice.actions;

export const toasterSelector = (state: RootState) => state.toasterReducer;

export default toasterSlice.reducer;
