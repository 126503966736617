import { PreLoader } from '@/components';
import { FC } from 'react';
import RegisterController from './components/RegisterController';

const BCCRegister: FC = () => {
  const slug = 'bcc';

  return slug ? <RegisterController slug={slug} /> : <PreLoader showText />;
};

export default BCCRegister;
