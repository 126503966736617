import React from 'react';

import classNames from 'classnames';
import { NavigateFunction } from 'react-router-dom';

import back from './assets/icons/back.svg';
import styles from './index.module.scss';

declare global {
  interface Window {
    closeHandler: {
      postMessage: (message: string) => void;
    };
  }
}

type TitleBlockProps = {
  children: React.ReactNode | React.ReactNode[];
  navigate: NavigateFunction;
  additionalClassName?: string;
  isMobile?: boolean;
  withBackBtn?: boolean;
  withCloseBtn?: boolean;
};

const TitleBlock: React.FC<TitleBlockProps> = ({
  children,
  navigate,
  additionalClassName,
  isMobile = false,
  withBackBtn = false,
  withCloseBtn = true,
}) => {
  return (
    <div className={classNames(styles.title, additionalClassName)}>
      {withBackBtn && (
        <div className={styles.backBtn} onClick={() => navigate(-1)}>
          <img src={back} alt="back-icon" className={styles.backBtnImg} />
        </div>
      )}
      {children}
      {isMobile && withCloseBtn && (
        <div
          className={styles.closeBtn}
          onClick={() => {
            if (window.closeHandler) {
              window.closeHandler.postMessage('');
            }
          }}
        >
          &times;
        </div>
      )}
    </div>
  );
};

export default TitleBlock;
