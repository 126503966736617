import styles from './index.module.scss';

import apple from '@/assets/shuttle/badges/apple.svg';
import google from '@/assets/shuttle/badges/google.png';

function badges() {
  return (
    <div className={styles.badges}>
      <div className={styles.badgesTitle}>Скачать приложение</div>
      <div className={styles.badgesApple}>
        <img
          src={apple}
          alt="Apple"
          onClick={() => (window.location.href = 'https://apps.apple.com/us/app/uvu-shuttle/id6443843916')}
        />
      </div>
      <div className={styles.badgesGoogle}>
        <img
          src={google}
          alt="Google"
          onClick={() => (window.location.href = 'https://play.google.com/store/apps/details?id=kz.uvu.shuttle')}
        />
      </div>
    </div>
  );
}

export default badges;
