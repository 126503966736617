import ellipse from '@/assets/shuttle/icons/ellipse.svg';
import { useAppDispatch } from '@/shuttle/app/hooks';
import { setPhone } from '@/store/slices/mainSlice';
import { isValidNumber, parseNumber } from '@/utils/phone';
import { tLogCustomEvent } from '@/utils/tracking';
import { Formik } from 'formik';
import { FC, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import styles from './index.module.scss';

type PhoneFormProps = {
  circle?: boolean;
};

type FormValues = {
  phone: string;
};

interface FormErrors {
  phone?: string;
}

const PhoneForm: FC<PhoneFormProps> = ({ circle = true }) => {
  const dispatch = useAppDispatch();
  const initialValues: FormValues = { phone: '' };
  const [startedTypingPhoneValue, setStartedTypingPhoneValue] = useState(false);

  const handleFormSubmit = (values: FormValues) => {
    dispatch(setPhone(parseNumber(values.phone)));
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: FormValues, { setSubmitting }) => {
        setSubmitting(false);
        handleFormSubmit(values);
      }}
      validate={(values: FormValues): FormErrors => {
        const errors: FormErrors = {};
        if (!isValidNumber(values.phone)) {
          errors.phone = 'Укажите ваш номер телефона';
        }

        return errors;
      }}
    >
      {({ values, handleChange }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          if (isValidNumber(values.phone)) {
            handleFormSubmit(values);
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [values.phone]);

        return (
          <form className={styles.phone}>
            <label htmlFor="phone" className={styles.phoneText}>
              {circle ? (
                <img src={ellipse} alt="circle" className={styles.circle} />
              ) : (
                <span className={styles.space}></span>
              )}
              Введи номер телефона
            </label>
            <InputMask
              mask="+7 (999) 999 99 99"
              placeholder="+7 (***) *** ** **"
              className={''}
              value={values.phone}
              onChange={(e) => {
                handleChange(e);
                if (!startedTypingPhoneValue) {
                  setStartedTypingPhoneValue(true);
                  tLogCustomEvent('started_typing_phone');
                }
              }}
              onBlur={({ target: { value } }) => {
                setStartedTypingPhoneValue(false);
                if (isValidNumber(value)) {
                  tLogCustomEvent('finished_typing_phone');
                }
              }}
              type="tel"
              id="phone"
            />
            {/*{errors.phone ? <div className={styles.phoneError}>{errors.phone}</div> : null}*/}
          </form>
        );
      }}
    </Formik>
  );
};

export default PhoneForm;
