import circle from '@/assets/shuttle/icons/circle.svg';
import PassengerCount from '@/components/shuttle/molecules/PassengerCount';
import { setSubscriptionKind } from '@/projects/shuttle/store/slices/paymentSlice';
import { useAppDispatch } from '@/shuttle/app/hooks';
import { routeDirection as RouteDirectionConstnat, SubscriptionKind, subscriptionKind } from '@/shuttle/constants';
import { TSubscriptionType, tLogCustomEvent } from '@/utils/tracking';
import classNames from 'classnames';
import { FC, useEffect } from 'react';

import styles from './index.module.scss';

type SubscriptionProps = {
  subscriptionKindValue: SubscriptionKind;
  onSubscriptionClick: (value: SubscriptionKind) => void;
  passengerCount: number;
  oneTripPrice: number | null;
  subscriptionPrice: number | null;
  routeDirection: string;
  onPassengerChange: (value: number) => void;
};

function numFormat(num: number) {
  // Adds spaces between numbers. 10000 -> 10 000
  const numString = String(num);
  const match = numString.match(/^(\d*)$/);
  if (match) {
    return match[1].replace(/\B(?=(?:\d{3})*$)/g, ' ');
  } else {
    return numString;
  }
}

const Subscription: FC<SubscriptionProps> = ({
  subscriptionKindValue,
  onSubscriptionClick,
  passengerCount,
  oneTripPrice,
  subscriptionPrice,
  onPassengerChange,
  routeDirection,
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSubscriptionKind(SubscriptionKind.MONTH_SUB));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={classNames(styles.danger)}>● количество мест ограничено</div>

      {routeDirection === RouteDirectionConstnat.FROM && (
        <div className={classNames(styles.subscriptionKind)}>
          <input
            type="radio"
            name="kind"
            id="subscription-kind-daily"
            className={styles.subscriptionKindInput}
            value={SubscriptionKind.DAY}
            checked={subscriptionKindValue === SubscriptionKind.DAY}
            onChange={() => {
              onSubscriptionClick(SubscriptionKind.DAY);
              tLogCustomEvent('change_subscription', { type: TSubscriptionType.DAY });
            }}
          />
          <label htmlFor="subscription-kind-daily" className={classNames(styles.subscriptionKindItem)}>
            <div className={classNames(styles.subscriptionKindTitle)}>
              Разовая поездка
              <br />
              {numFormat(oneTripPrice! * passengerCount)} тг &nbsp;&nbsp;
            </div>
          </label>
        </div>
      )}
      <div className={classNames(styles.subscriptionKind)}>
        <input
          type="radio"
          name="kind"
          id="subscription-kind-month-sub"
          className={styles.subscriptionKindInput}
          value={subscriptionKind.MONTH_SUB}
          checked={subscriptionKindValue === subscriptionKind.MONTH_SUB}
          onChange={() => {
            onSubscriptionClick(SubscriptionKind.MONTH_SUB);
            tLogCustomEvent('change_subscription', { type: TSubscriptionType.MONTH_SUB });
          }}
        />
        <label htmlFor="subscription-kind-month-sub" className={styles.subscriptionKindItem}>
          <div className={classNames(styles.subscriptionKindTitle)}>
            Месячная подписка <br />
            {numFormat(subscriptionPrice! * passengerCount)} тг &nbsp;&nbsp;
          </div>
          <div className={styles.subscriptionKindBody}>
            Стоимость одной поездки 680 тг <br />
            несмотря на время и погоду
          </div>
        </label>
      </div>
      <div className={styles.passengerCountBlock}>
        <PassengerCount passengerCount={passengerCount} updateCount={(value) => onPassengerChange(value)} />
      </div>

      <div className={styles.trialInfo}>
        {routeDirection === RouteDirectionConstnat.TO && (
          <div className={styles.trialInfoTitle}>
            <div>
              <img src={circle} alt="circle" className={styles.circle} />
            </div>
            <div>Твое место забронировано на 12 месяцев</div>
          </div>
        )}

        <div className={styles.trialInfoTitle}>
          <div>
            <img src={circle} alt="circle" className={styles.circle} />
          </div>
          <div>Отмена поездки либо подписки в любой момент</div>
        </div>

        {routeDirection === RouteDirectionConstnat.TO && (
          <div className={styles.trialInfoTitle}>
            <div>
              <img src={circle} alt="circle" className={styles.circle} />
            </div>
            <div>Ежемесячная оплата</div>
          </div>
        )}
      </div>

      {/* <div className={styles.subscriptionKindFirstRow}>
        <input
          type="radio"
          name="kind"
          id="subscription-kind-daily"
          className={styles.subscriptionKindInput}
          value={SubscriptionKind.DAY}
          checked={subscriptionKindValue === SubscriptionKind.DAY}
          onChange={() => {
            onSubscriptionClick(SubscriptionKind.DAY);
            tLogCustomEvent('change_subscription', { type: TSubscriptionType.DAY });
          }}
        />
        <label htmlFor="subscription-kind-daily" className={classNames(styles.subscriptionKindItem)}>
          {oneTripPrice ? (
            <div>
              <div className={styles.subscriptionKindTitle}>Разовая</div>
              <div className={styles.subscriptionKindAmount}>{oneTripPrice * passengerCount} тг</div>
              <div className={styles.subscriptionKindDescription}>одна поездка</div>
            </div>
          ) : null}
        </label>
      </div>
      <div>
        <input
          type="radio"
          name="kind"
          id="subscription-kind-monthly"
          className={styles.subscriptionKindInput}
          value={SubscriptionKind.MONTH}
          checked={subscriptionKindValue === SubscriptionKind.MONTH}
          onChange={() => {
            onSubscriptionClick(SubscriptionKind.MONTH);
            tLogCustomEvent('change_subscription', { type: TSubscriptionType.MONTH });
          }}
        />
        <label
          htmlFor="subscription-kind-monthly"
          className={classNames(styles.subscriptionKindItem, styles.subscriptionKindItemMonthly)}
        >
          {monthlySubscriptionPrice ? (
            <div>
              <div className={styles.subscriptionKindTitle}>Месячная</div>
              <div className={styles.subscriptionKindAmount}>
                {Math.round((monthlySubscriptionPrice * passengerCount) / 22)} тг
              </div>
              <div className={styles.subscriptionKindDescription}>одна поездка</div>
              <div className={styles.subscriptionKindAllAmount}>
                {monthlySubscriptionPrice * passengerCount} тг/месяц
              </div>
            </div>
          ) : null}
        </label>
      </div> */}
    </>
  );
};

export default Subscription;
