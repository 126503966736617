import ToursController from '@/travel_shuttle/components/containers/ToursController';
import { tLogPageView } from '@/utils/tracking';
import { useEffect } from 'react';

const Tours = () => {
  useEffect(() => {
    tLogPageView();
  }, []);

  return <ToursController />;
};

export default Tours;
