import logo from '@/assets/shuttle/img/logoRed.png';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';

const Stub = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate('/');
    }, 5000);

    // Return a cleanup function
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.page}>
      <div className={styles.logo}>
        <img src={logo} alt="UvU" />
      </div>
      <span className={styles.span}>Вы будете перенаправлены на главную страницу через 5 секунд</span>
    </div>
  );
};

export default Stub;
