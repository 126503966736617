/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import * as Sentry from '@sentry/react';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

class AxiosInstance {
  private axiosInstance;

  constructor(baseURL: string) {
    this.axiosInstance = axios.create({
      baseURL,
      headers: this.getHeaders(),
    });
  }

  getHeaders() {
    const everywhere = { 'Content-Type': 'application/json' };
    return { ...everywhere };
  }

  async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.axiosInstance.get(url, config);
      return response.data;
    } catch (error) {
      Sentry.withScope((scope) => {
        const response = error.response;
        const errors = response ? response.data.errors : null;
        scope.setExtra('data', {
          ...error.response?.data,
          errors: errors ? errors.map((obj) => JSON.stringify(obj)) : [],
        });
        Sentry.captureException(error);
      });
      throw error;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.axiosInstance.post(url, data, config);
      return response.data;
    } catch (error) {
      Sentry.withScope((scope) => {
        const response = error.response;
        const errors = response ? response.data.errors : null;
        scope.setExtra('data', {
          ...error.response?.data,
          errors: errors ? errors.map((obj) => JSON.stringify(obj)) : [],
        });
        Sentry.captureException(error);
      });
      throw error;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async patch<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.axiosInstance.patch(url, data, config);
      return response.data;
    } catch (error) {
      Sentry.withScope((scope) => {
        const response = error.response;
        const errors = response ? response.data.errors : null;
        scope.setExtra('data', {
          ...error.response?.data,
          errors: errors ? errors.map((obj) => JSON.stringify(obj)) : [],
        });
        Sentry.captureException(error);
      });
      throw error;
    }
  }
}

export const axiosInstance = new AxiosInstance(import.meta.env.VITE_API_URL, {});
export const axiosFakeAPIInstance = new AxiosInstance(import.meta.env.VITE_FAKE_API_URL, {});
