import React from 'react';
import styles from './index.module.scss';

type TitleProps = {
  children: React.ReactNode | React.ReactNode[];
  marginTop?: number;
  marginBottom?: number;
};

const Title: React.FC<TitleProps> = ({ children, marginTop, marginBottom }) => {
  return (
    <h1
      className={styles.title}
      style={{
        marginTop: marginTop ? marginTop : 20,
        marginBottom: marginBottom ? marginBottom : 25,
      }}
    >
      {children}
    </h1>
  );
};

export default Title;
