import React from 'react';

import classNames from 'classnames';

import { addressType } from '@/@types/shuttle/address';

import mapIcon from '@/assets/shuttle/icons/map.svg';

import styles from './Select.module.scss';

type SearchInputProps = {
  setSelectedData: (value: addressType | null) => void;
  selectionData?: addressType[] | null;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  inputClassName?: string;
  mapBtnClassName?: string;
  selectionClassName?: string;
  placeholder?: string;
  customClassName?: string;
  customSelectionClassName?: string;
  value: string;
  forwardedRef: React.RefObject<SearchInputHandle | null>;
  disabled?: boolean;
};

export type SearchInputHandle = {
  setValue: (value: string) => void;
};

const Select: React.ForwardRefRenderFunction<SearchInputHandle, SearchInputProps> = (props) => {
  const [isSelectionShowed, setIsSelectionShowed] = React.useState(false);
  const [value, setValue] = React.useState(props.value);

  React.useImperativeHandle(
    props.forwardedRef,
    () => {
      return {
        setValue(value: string) {
          setValue(value);
        },
      };
    },
    []
  );

  return (
    <div
      className={classNames(styles.inputBlock, props.customClassName)}
      onClick={() => setIsSelectionShowed(!isSelectionShowed)}
    >
      <input
        placeholder={props.placeholder}
        className={classNames(styles.input, props.inputClassName)}
        onBlur={() => {
          setTimeout(() => {
            setIsSelectionShowed(false);
          }, 300);
        }}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          props.onChange && props.onChange(e.target.value);
        }}
        disabled={true}
      />
      <span className={classNames(styles.triangle, { [styles.active]: isSelectionShowed })}></span>
      {value && (
        <span
          className={styles.closeBtn}
          onClick={() => {
            setValue('');
            props.setSelectedData(null);
          }}
        >
          &#x2715;
        </span>
      )}
      {isSelectionShowed && props.selectionData && props.selectionData.length > 0 && (
        <div className={classNames(styles.selection, props.customSelectionClassName)}>
          {props.selectionData.map((s, index) => (
            <div
              key={index}
              className={styles.select}
              onClick={() => {
                setValue(s.name);
                props.setSelectedData(s);
              }}
            >
              <img src={mapIcon} alt="map" className={styles.selectImg} />
              <div className={classNames(styles.selectName, props.selectionClassName)}>
                {s.name.replace(', Казахстан', '')}
              </div>
            </div>
          ))}
        </div>
      )}
      {isSelectionShowed && props.selectionData && props.selectionData.length === 0 && (
        <div className={classNames(styles.selection, props.customSelectionClassName)}>
          <div
            className={styles.select}
            onClick={() => {
              setIsSelectionShowed(false);
            }}
          >
            <div className={styles.selectName}> Попробуйте полный адрес</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Select;
