import { addressType } from '@/@types/shuttle/address';
import logo from '@/assets/shuttle/img/logoRed.png';
import { AddressSelectMap, Btn, PreLoader, SearchInput } from '@/components';
import { AddressTypeEnum, SearchInputHandle } from '@/components/shared/molecules/SearchInput';
import LatestTrips from '@/components/shuttle/molecules/LatestTrips';
import { FakePhoneNumber } from '@/constants';
import { useAppDispatch, useAppSelector } from '@/fake_shuttle/app/hooks';
import { MobileAppPreview, Select } from '@/fake_shuttle/components/atoms';
import {
  addressSelector,
  getLatestSuccessfulSchedules,
  getSuggestionsAsync,
  setDropOffAddress,
  setPickupAddress,
} from '@/fake_shuttle/store/slices/addressSlice';
import { getAddressName } from '@/utils/address';
import { useDebounce } from '@/utils/debounce';
import { isValidNumber } from '@/utils/phone';
import { TAddressSource, TAddressType, tLogCustomEvent } from '@/utils/tracking';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import styles from './index.module.scss';

import { cities } from '@/constants';
import { funnelSelector, getFunnelInfoAsync, setSlug } from '@/projects/fake_shuttle/store/slices/funnelSlice';
import { setToaster } from '@/store/slices/toasterSlice';
import L from 'leaflet';
import { useNavigate, useParams } from 'react-router-dom';

export type AddressControllerProps = {
  phone: string;
  isMobile: boolean;
  onPhoneModalShow: () => void;
  onSubmit: () => void;
  modal: string | null;
};

enum addressSelectChoices {
  FROM,
  TO,
}

const AddressesController: FC<AddressControllerProps> = ({ phone, isMobile, onPhoneModalShow, onSubmit, modal }) => {
  const { isLoading, suggestions, latestSuccessfulTrips, pickupAddress, dropOffAddress } =
    useAppSelector(addressSelector);
  const {
    name: funnelName,
    inputPlaceholder,
    pickupAddresses,
    dropoffAddresses,
    cityCenterCoordinates: funnelCityCenter,
  } = useAppSelector(funnelSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { slug } = useParams();

  const pickupAddressForwardedRef = useRef<SearchInputHandle>(null);
  const dropOffAddressForwardedRef = useRef<SearchInputHandle>(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const [canSubmit, setCanSubmit] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [pickupStartedTyping, SetPickupStartedTyping] = useState(false);
  const [dropOffStartedTyping, SetDropOffStartedTyping] = useState(false);
  const [routeDirection, setRouteDirection] = useState<addressSelectChoices>(addressSelectChoices.FROM);

  useEffect(() => {
    if (!funnelName && slug) {
      dispatch(setSlug(slug));
      dispatch(getFunnelInfoAsync({ slug }))
        .unwrap()
        .catch(() => navigate('/'));
    }
  }, [funnelName, slug, dispatch, navigate]);

  const localPhone = useMemo(() => {
    if (phone && phone !== FakePhoneNumber) {
      return phone;
    }
    return null;
  }, [phone]);

  useEffect(() => {
    if (localPhone) {
      setInitialLoading(true);
      dispatch(
        getLatestSuccessfulSchedules({
          phone: localPhone,
        })
      ).finally(() => setInitialLoading(false));
    } else {
      setInitialLoading(false);
    }
  }, [localPhone, dispatch]);

  useEffect(() => {
    if (pickupAddresses.length == 1) {
      dispatch(setPickupAddress(pickupAddresses[0]));
    } else if (dropoffAddresses.length == 1) {
      dispatch(setDropOffAddress(dropoffAddresses[0]));
    }
  }, [dispatch, pickupAddresses, dropoffAddresses]);

  const onAddressSelect = async (latLng: L.LatLng) => {
    const addressObj: addressType = {
      name: 'Точка на карте',
      location: {
        lat: latLng.lat,
        lng: latLng.lng,
      },
    };

    try {
      const result = await getAddressName(addressObj);

      if (result.status === 'success') {
        if (routeDirection === addressSelectChoices.FROM) {
          dispatch(setPickupAddress(result.data));
          tLogCustomEvent('select_address', {
            type: TAddressType.PICKUP,
            source: TAddressSource.MAP,
            address: result.data.name,
          });
          pickupAddressForwardedRef.current!.setValue(result.data.name);
          if (dropOffAddress) setCanSubmit(true);
        } else {
          dispatch(setDropOffAddress(result.data));
          tLogCustomEvent('select_address', {
            type: TAddressType.DROPOFF,
            source: TAddressSource.MAP,
            address: result.data.name,
          });
          dropOffAddressForwardedRef.current!.setValue(result.data.name);
          if (pickupAddress) setCanSubmit(true);
        }
      }
    } catch {
      dispatch(
        setToaster({
          isVisible: true,
          type: 'error',
          message: 'Произошла ошибка. Попробуйте еще раз или выберите другой адрес.',
        })
      );
    }
    setShowMap(false);
  };

  useEffect(() => {
    if (!phone && pickupAddress && pickupAddress.location && dropOffAddress && dropOffAddress.location) {
      onPhoneModalShow();
    }

    if (isMobile && phone && pickupAddress && dropOffAddress && canSubmit) {
      onSubmit();
    }
  }, [isMobile, phone, modal, pickupAddress, dropOffAddress, canSubmit, onPhoneModalShow, onSubmit]);

  const getSuggestions = (value: string) => {
    if (value.trim() === '') return;

    dispatch(getSuggestionsAsync(value));
  };

  const handleInputChange = async (value: string, direction: string) => {
    if (!value) return;

    const data = { name: value, location: null };
    if (direction === TAddressType.PICKUP) {
      dispatch(setPickupAddress(data));
    } else {
      dispatch(setDropOffAddress(data));
    }
  };

  const delayedGetSuggestions = useDebounce(getSuggestions, 1000);

  return (
    <>
      {isLoading && <PreLoader />}
      {initialLoading && <PreLoader showText showBackground />}
      {showMap && (
        <AddressSelectMap
          onSelect={onAddressSelect}
          onBackBtnClick={() => setShowMap(false)}
          forwardClassName="ConfirmLocationBtn"
          mapCenter={funnelCityCenter ? [funnelCityCenter.lat, funnelCityCenter.lng] : cities.astana.center}
        />
      )}
      <div className={styles.logo}>
        <img src={logo} alt="UvU" />
      </div>
      <div className={styles.addresses}>
        <>
          <div className={styles.form}>
            <div className={styles.formStick} />
            {/* Show select if we get funnel.pickupAdresses, otherwise - default search input. */}
            {pickupAddresses.length ? (
              <Select
                placeholder={inputPlaceholder}
                selectionData={pickupAddresses}
                setSelectedData={(value: AddressType | null) => {
                  dispatch(setPickupAddress(value!));
                  if (value?.name) {
                    tLogCustomEvent('select_address', {
                      type: TAddressType.PICKUP,
                      source: TAddressSource.AUTOCOMPLETE,
                      address: value.name,
                    });
                    if (value && dropOffAddress) setCanSubmit(true);
                  }
                }}
                customClassName={styles.dropoffInput}
                customSelectionClassName={styles.toSelection}
                value={pickupAddresses.length == 1 ? pickupAddresses[0].name : pickupAddress ? pickupAddress.name : ''}
                forwardedRef={pickupAddressForwardedRef}
              />
            ) : (
              <SearchInput
                type={AddressTypeEnum.PICKUP}
                inputClassName="PickupAddressSearchInput"
                mapBtnClassName="PickupAddressMapBtn"
                selectionClassName="PickupAddressSelection"
                placeholder="Откуда едем"
                selectionData={pickupAddresses.length ? pickupAddresses : suggestions}
                setSelectedData={(value) => {
                  dispatch(setPickupAddress(value!));
                  if (value && dropOffAddress) setCanSubmit(true);
                }}
                customSelectionClassName={styles.fromSelection}
                value={pickupAddress ? pickupAddress.name : ''}
                forwardedRef={pickupAddressForwardedRef}
                onChange={(value) => {
                  delayedGetSuggestions(value);
                  handleInputChange(value, TAddressType.PICKUP);

                  if (!pickupStartedTyping) {
                    SetPickupStartedTyping(true);
                    tLogCustomEvent('started_typing_address', { type: TAddressType.PICKUP });
                  }
                }}
                onBlur={() => SetPickupStartedTyping(false)}
                onMapBtnClick={() => {
                  setRouteDirection(addressSelectChoices.FROM);
                  setShowMap(true);
                }}
              />
            )}
            {/* Show select if we get funnel.dropoffAddresses, otherwise - default search input. */}
            {dropoffAddresses.length ? (
              <Select
                placeholder={inputPlaceholder}
                selectionData={dropoffAddresses}
                setSelectedData={(value: AddressType | null) => {
                  dispatch(setDropOffAddress(value!));
                  if (value?.name) {
                    tLogCustomEvent('select_address', {
                      type: TAddressType.DROPOFF,
                      source: TAddressSource.AUTOCOMPLETE,
                      address: value.name,
                    });
                    if (value && pickupAddress) setCanSubmit(true);
                  }
                }}
                customClassName={styles.dropoffInput}
                customSelectionClassName={styles.toSelection}
                value={
                  dropoffAddresses.length == 1 ? dropoffAddresses[0].name : dropOffAddress ? dropOffAddress.name : ''
                }
                forwardedRef={dropOffAddressForwardedRef}
              />
            ) : (
              <SearchInput
                type={AddressTypeEnum.DROPOFF}
                inputClassName="DropoffAddressSearchInput"
                mapBtnClassName="DropoffAddressMapBtn"
                selectionClassName="DropoffAddressSelection"
                placeholder="Куда едем"
                selectionData={dropoffAddresses.length ? dropoffAddresses : suggestions}
                setSelectedData={(value) => {
                  dispatch(setDropOffAddress(value!));
                  if (value && pickupAddress) setCanSubmit(true);
                }}
                customSelectionClassName={styles.toSelection}
                value={dropOffAddress ? dropOffAddress.name : ''}
                forwardedRef={dropOffAddressForwardedRef}
                onChange={(value) => {
                  delayedGetSuggestions(value);
                  handleInputChange(value, TAddressType.DROPOFF);

                  if (!dropOffStartedTyping) {
                    SetDropOffStartedTyping(true);
                    tLogCustomEvent('started_typing_address', { type: TAddressType.DROPOFF });
                  }
                }}
                onBlur={() => SetDropOffStartedTyping(false)}
                onMapBtnClick={() => {
                  setRouteDirection(addressSelectChoices.TO);
                  setShowMap(true);
                }}
              />
            )}
          </div>
          {latestSuccessfulTrips && latestSuccessfulTrips.length ? (
            <LatestTrips isMobile={isMobile} latestTrips={latestSuccessfulTrips} />
          ) : (
            <MobileAppPreview />
          )}
          <Btn
            type="button"
            color="black"
            onClick={() => {
              if (isValidNumber(phone)) {
                onSubmit();
              } else {
                onPhoneModalShow();
              }
            }}
            disabled={isLoading || !pickupAddress || !pickupAddress}
            isFixedBottom={true}
          >
            Продолжить
          </Btn>
        </>
      </div>
    </>
  );
};

export default AddressesController;
