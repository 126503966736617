import { useAppDispatch, useAppSelector } from '@/shuttle/app/hooks';
import { setTours, toursSelector } from '@/shuttle/store/slices/toursSlice';
import { getDateDayFullMonthString, getDateWeekdayString } from '@/utils/date';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Tour from '../Tour';
import styles from './index.module.scss';

type MappingType = {
  [key: string]: string;
};

function toGenetiveCase(weekday: string): string {
  const mappping: MappingType = {
    Понедельник: 'С понедельника',
    Вторник: 'Со вторника',
    Среда: 'Со среды',
    Четверг: 'С четверга',
    Пятница: 'С пятницы',
    Суббота: 'С субботы',
    Воскресенье: 'С воскресенья',
  };
  return mappping[weekday];
}

type TourSlideProps = {
  data: tourDataType;
};

const TourSlide: FC<TourSlideProps> = ({ data }) => {
  const navigate = useNavigate();
  const date = new Date(data.tour.date);
  const dispatch = useAppDispatch();
  const { tourList } = useAppSelector(toursSelector);

  async function onClick() {
    await dispatch(setTours(tourList[data.tour.route_desired_time]));
    navigate('/tours');
  }

  return (
    <div className={styles.bg}>
      <div className={styles.date}>
        {toGenetiveCase(getDateWeekdayString(date))}, {getDateDayFullMonthString(date)}
      </div>
      <div className={styles.wrapper} onClick={onClick}>
        <Tour data={data} />
      </div>
    </div>
  );
};

export default TourSlide;
