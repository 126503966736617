import { Btn } from '@/components';
import { isValidNumber, parseNumber } from '@/utils/phone';
import classNames from 'classnames';
import { FC, useState } from 'react';
import InputMask from 'react-input-mask';
import styles from './index.module.scss';

type PopupProps = {
  onSubmit: (phone: string) => void;
  onClose?: () => void;
};

const PhoneModal: FC<PopupProps> = ({ onClose, onSubmit }) => {
  // TODO: What kind of magic is used here? Can we simplify it?
  const [phone, setPhone] = useState('');

  return (
    <div
      className={classNames(styles.popup, {
        [`${styles.isActive}`]: true,
      })}
    >
      <div className={styles.background}></div>
      <div className={styles.content}>
        <div className={styles.popupTitle}>Для продолжения введите номер телефона</div>
        <div className={styles.popupBody}>
          <div className={styles.phone}>
            <InputMask
              mask="+7 (999) 999 99 99"
              placeholder="+7 (***) *** ** **"
              className={classNames('input', styles.input)}
              value={phone}
              onChange={({ target: { value } }) => setPhone(value)}
              onBlur={({ target: { value } }) => isValidNumber(value)}
              type="tel"
              id="phone"
            />
          </div>
          <Btn
            color="black"
            onClick={() => {
              onSubmit(parseNumber(phone));
            }}
            disabled={!isValidNumber(phone)}
            customClassName={styles.popupBtn}
          >
            Посмотреть маршруты
          </Btn>
        </div>
        <button onClick={onClose} className={styles.btn}>
          ×
        </button>
      </div>
    </div>
  );
};

export default PhoneModal;
