import { Benefits, Btn, FioForm, PhoneForm, PreLoader } from '@/components';
import { useAppDispatch, useAppSelector } from '@/fake_shuttle/app/hooks';
import { FC, useEffect } from 'react';
import styles from './index.module.scss';

import {
  funnelSelector,
  funnelStatus,
  getFunnelInfoAsync,
  setSlug,
} from '@/projects/fake_shuttle/store/slices/funnelSlice';

import { mainSelector } from '@/store/slices/mainSlice';
import { isValidNumber } from '@/utils/phone';
import { Link, useNavigate } from 'react-router-dom';

import uvuLogo from '@/assets/shuttle/img/logoRed.png';
import { DisabledFunnel } from '@/fake_shuttle/components/atoms';
import { setIsLoading } from '@/projects/fake_shuttle/store/slices/funnelSlice';
import { getOrCreateCustomerAsync } from '@/projects/fake_shuttle/store/slices/registerSlice';

type RegisterControllerProps = {
  slug: string;
};

const RegisterController: FC<RegisterControllerProps> = ({ slug }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { phone, fio } = useAppSelector(mainSelector);
  const { name, logo, isLoading, isFIONeeded, status } = useAppSelector(funnelSelector);

  useEffect(() => {
    if (slug) {
      dispatch(setSlug(slug));
      dispatch(getFunnelInfoAsync({ slug }))
        .unwrap()
        .catch(() => navigate('/'));
    } else {
      dispatch(setIsLoading(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  const onSubmit = () => {
    dispatch(getOrCreateCustomerAsync({ phone, fio }))
      .unwrap()
      .then(() => navigate(`addresses`));
  };

  return (
    <div className={styles.wrapper}>
      <>
        {isLoading ? (
          <PreLoader showText showBackground />
        ) : status === funnelStatus.DISABLED ? (
          <DisabledFunnel />
        ) : (
          <div className={styles.main}>
            <div className={styles.head}>
              <div className={styles.logo}>
                <img src={uvuLogo} alt="UvU" />
              </div>
              {logo && (
                <>
                  <div className={styles.x}>X</div>
                  <div className={styles.company}>
                    <img src={logo} alt={name} />
                  </div>
                </>
              )}
            </div>

            {isFIONeeded && <FioForm circle={false} />}
            <PhoneForm circle={false} />

            <Benefits withBus />

            <div>
              <Btn
                color="black"
                customClassName={styles.btn}
                disabled={isLoading || !isValidNumber(phone) || (isFIONeeded && !fio)}
                onClick={onSubmit}
                isFixedBottom={true}
              >
                Продолжить
              </Btn>
            </div>
          </div>
        )}
      </>
      <div className={styles.law}>
        <p>Нажимая "Продолжить", вы соглашаетесь с</p>
        <p>
          <Link to="/privacy">Политикой конфеденциальности</Link>
        </p>
        <p>
          <Link to="/oferta">Договором оферты</Link>
        </p>
      </div>
    </div>
  );
};

export default RegisterController;
