import { addressType } from '@/@types/shuttle/address';
import calendar from '@/assets/shuttle/icons/calendar.svg';
import { AddressSelectMap, Benefits, Btn, PreLoader, SearchInput, Select } from '@/components';

import { AddressTypeEnum, SearchInputHandle } from '@/components/shared/molecules/SearchInput';
import LatestTrips from '@/components/shuttle/molecules/LatestTrips';
import { FakePhoneNumber } from '@/constants';
import { useAppDispatch, useAppSelector } from '@/shuttle/app/hooks';
import {
  DayJsValueType,
  addressSelector,
  getLatestSuccessfulSchedules,
  getSuggestionsAsync,
  getUpcomingTours,
  setDropOffAddress,
  setPickupAddress,
  setSelectedDate,
} from '@/shuttle/store/slices/addressSlice';
import { getAddressName } from '@/utils/address';
import { useDebounce } from '@/utils/debounce';
import { isValidNumber } from '@/utils/phone';
import { TAddressSource, TAddressType, tLogCustomEvent } from '@/utils/tracking';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styles from './index.module.scss';

import { cities } from '@/constants';
import { setToaster } from '@/store/slices/toasterSlice';
import L from 'leaflet';

export type AddressControllerProps = {
  phone: string;
  isMobile: boolean;
  onPhoneModalShow: () => void;
  onSubmit: () => void;
};

enum addressSelectChoices {
  FROM,
  TO,
}

const AddressesController: FC<AddressControllerProps> = ({ phone, isMobile, onPhoneModalShow, onSubmit }) => {
  const {
    isLoading,
    suggestions,
    latestSuccessfulTrips,
    pickupAddress,
    dropOffAddress,
    upcomingTourDates,
    selectedDate,
  } = useAppSelector(addressSelector);
  const dispatch = useAppDispatch();

  const pickupAddressForwardedRef = useRef<SearchInputHandle>(null);
  const dropOffAddressForwardedRef = useRef<SearchInputHandle>(null);
  const formRef = useRef<HTMLInputElement>(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const [showMap, setShowMap] = useState(false);
  const [pickupStartedTyping, SetPickupStartedTyping] = useState(false);
  const [dropOffStartedTyping, SetDropOffStartedTyping] = useState(false);
  const [routeDirection, setRouteDirection] = useState<addressSelectChoices>(addressSelectChoices.FROM);

  const [searchParams] = useSearchParams();
  const pickup_address = searchParams.get('pickup_address');
  const dropoff_address = searchParams.get('dropoff_address');

  const localPhone = useMemo(() => {
    if (phone && phone !== FakePhoneNumber) {
      return phone;
    }

    return null;
  }, [phone]);

  useEffect(() => {
    if (localPhone) {
      setInitialLoading(true);
      dispatch(
        getLatestSuccessfulSchedules({
          phone: localPhone,
        })
      ).finally(() => setInitialLoading(false));
    } else {
      setInitialLoading(false);
    }
  }, [localPhone, dispatch]);

  useEffect(() => {
    if (pickup_address) {
      pickupAddressForwardedRef.current?.setValue(pickup_address);
      dispatch(setPickupAddress({ name: pickup_address, location: null }));
    }
  }, [pickup_address, dispatch]);

  useEffect(() => {
    if (dropoff_address) {
      dropOffAddressForwardedRef.current?.setValue(dropoff_address);
      dispatch(setDropOffAddress({ name: dropoff_address, location: null }));
    }
  }, [dropoff_address, dispatch]);

  useEffect(() => {
    if (!upcomingTourDates.length) {
      dispatch(getUpcomingTours()).finally(() => setInitialLoading(false));
    }
  }, [upcomingTourDates, dispatch]);

  const onAddressSelect = async (latLng: L.LatLng) => {
    const addressObj: addressType = {
      name: 'Точка на карте',
      location: {
        lat: latLng.lat,
        lng: latLng.lng,
      },
    };

    try {
      const result = await getAddressName(addressObj);

      if (result.status === 'success') {
        if (routeDirection === addressSelectChoices.FROM) {
          dispatch(setPickupAddress(result.data));
          tLogCustomEvent('select_address', {
            type: TAddressType.PICKUP,
            source: TAddressSource.MAP,
            address: result.data.name,
          });
          pickupAddressForwardedRef.current!.setValue(result.data.name);
        } else {
          dispatch(setDropOffAddress(result.data));
          tLogCustomEvent('select_address', {
            type: TAddressType.DROPOFF,
            source: TAddressSource.MAP,
            address: result.data.name,
          });
          dropOffAddressForwardedRef.current!.setValue(result.data.name);
        }
      }
    } catch {
      dispatch(
        setToaster({
          isVisible: true,
          type: 'error',
          message: 'Произошла ошибка. Попробуйте еще раз или выберите другой адрес.',
        })
      );
    }
    setShowMap(false);
  };

  const getSuggestions = (value: string) => {
    if (value.trim() === '') return;

    dispatch(getSuggestionsAsync(value));
  };

  const handleInputChange = async (value: string, direction: string) => {
    if (!value) return;

    const data = { name: value, location: null };
    if (direction === TAddressType.PICKUP) {
      dispatch(setPickupAddress(data));
    } else {
      dispatch(setDropOffAddress(data));
    }
  };

  const changeAddresses = () => {
    const tempPickupAddress = pickupAddress;
    const tempDropoffAddress = dropOffAddress;
    dispatch(setPickupAddress(dropOffAddress!));
    dispatch(setDropOffAddress(tempPickupAddress!));

    pickupAddressForwardedRef.current?.setValue(tempDropoffAddress?.name || '');
    dropOffAddressForwardedRef.current?.setValue(tempPickupAddress?.name || '');
  };

  const delayedGetSuggestions = useDebounce(getSuggestions, 1000);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    formRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      {isLoading && <PreLoader />}
      {initialLoading && <PreLoader showBackground showText />}

      {showMap && (
        <AddressSelectMap
          onSelect={onAddressSelect}
          onBackBtnClick={() => setShowMap(false)}
          forwardClassName="ConfirmLocationBtn"
          mapCenter={cities.astana.center}
        />
      )}
      <div className={styles.bg}></div>
      <div className={styles.addresses}>
        <>
          <div className={styles.title}>Поехали вместе</div>
          <div className={styles.form} ref={formRef} onClick={handleClick}>
            <div className={styles.formStick} />
            <SearchInput
              type={AddressTypeEnum.PICKUP}
              inputClassName="PickupAddressSearchInput"
              mapBtnClassName="PickupAddressMapBtn"
              selectionClassName="PickupAddressSelection"
              placeholder="Откуда едем"
              selectionData={suggestions}
              setSelectedData={(value) => {
                dispatch(setPickupAddress(value!));
              }}
              customSelectionClassName={styles.fromSelection}
              value={pickupAddress ? pickupAddress.name : ''}
              forwardedRef={pickupAddressForwardedRef}
              onChange={(value) => {
                delayedGetSuggestions(value);
                handleInputChange(value, TAddressType.PICKUP);

                if (!pickupStartedTyping) {
                  SetPickupStartedTyping(true);
                  tLogCustomEvent('started_typing_address', { type: TAddressType.PICKUP });
                }
              }}
              onBlur={() => SetPickupStartedTyping(false)}
              onMapBtnClick={() => {
                setRouteDirection(addressSelectChoices.FROM);
                setShowMap(true);
              }}
            />
            <SearchInput
              type={AddressTypeEnum.DROPOFF}
              inputClassName="DropoffAddressSearchInput"
              mapBtnClassName="DropoffAddressMapBtn"
              selectionClassName="DropoffAddressSelection"
              placeholder="Куда едем"
              selectionData={suggestions}
              setSelectedData={(value) => {
                dispatch(setDropOffAddress(value!));
              }}
              customSelectionClassName={styles.toSelection}
              value={dropOffAddress ? dropOffAddress.name : ''}
              forwardedRef={dropOffAddressForwardedRef}
              onChange={(value) => {
                delayedGetSuggestions(value);
                handleInputChange(value, TAddressType.DROPOFF);

                if (!dropOffStartedTyping) {
                  SetDropOffStartedTyping(true);
                  tLogCustomEvent('started_typing_address', { type: TAddressType.DROPOFF });
                }
              }}
              onBlur={() => SetDropOffStartedTyping(false)}
              onMapBtnClick={() => {
                setRouteDirection(addressSelectChoices.TO);
                setShowMap(true);
              }}
            />
            <div className={styles.formBtn} onClick={changeAddresses}>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#666666">
                <path d="M16 4L11 9L12.413 10.414L14.999 7.829V24.172L12.413 21.586L11 23.001L15.999 28L20.999 23.001L19.586 21.586L17 24.172V7.829L19.586 10.414L20.999 9L16 4Z"></path>
              </svg>
            </div>
          </div>
          {upcomingTourDates.length ? (
            <div className={styles.formDate}>
              <img src={calendar} alt="calendar" className={styles.calendar} />
              <Select
                options={upcomingTourDates}
                value={selectedDate}
                menuClassName={styles.menuClassName}
                onChange={(value) => {
                  dispatch(setSelectedDate(value as DayJsValueType));
                }}
              />
            </div>
          ) : null}
          {latestSuccessfulTrips && latestSuccessfulTrips.length ? (
            <LatestTrips isMobile={isMobile} latestTrips={latestSuccessfulTrips} />
          ) : (
            <Benefits />
          )}
          <Btn
            type="button"
            color="black"
            onClick={() => {
              if (isValidNumber(phone)) {
                onSubmit();
              } else {
                onPhoneModalShow();
              }
            }}
            disabled={isLoading || !pickupAddress || !dropOffAddress}
            isFixedBottom={true}
          >
            Посмотреть маршруты
          </Btn>
        </>
      </div>
    </>
  );
};

export default AddressesController;
