import bus from '@/assets/img/busRed.png';
import { Btn } from '@/components';
import ellipse from '@/projects/school/assets/img/ellipse.svg';
import { schools } from '@/projects/school/constants';
import { Head, Title } from '@/school/components';
import { tLogPageView } from '@/utils/tracking';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './index.module.scss';

export const Welcome = () => {
  const navigate = useNavigate();

  useEffect(() => tLogPageView(), []);

  const params = useParams();
  const slug = params.school;
  if (!slug || !(slug in schools)) return <>No information</>;

  const schoolName = schools[slug].name;
  return (
    <div className={styles.main}>
      <Head />
      <Title>Официальный партнер по развозке школы "{schoolName}"</Title>

      <div className={styles.features}>
        <div className={styles.featuresItem}>
          <div className={styles.featuresItemImg}>
            <img src={ellipse} alt="circle" className={styles.circle} />
          </div>
          <div className={styles.featuresItemText}>Проверенные водители</div>
        </div>

        <div className={styles.featuresItem}>
          <div className={styles.featuresItemImg}>
            <img src={ellipse} alt="circle" className={styles.circle} />
          </div>
          <div className={styles.featuresItemText}>Комфортабельные автобусы</div>
        </div>

        <div className={styles.featuresItem}>
          <div className={styles.featuresItemImg}>
            <img src={ellipse} alt="circle" className={styles.circle} />
          </div>
          <div className={styles.featuresItemText}>Все в одном мобильном приложении</div>
        </div>
      </div>

      <div className={styles.bus}>
        <img src={bus} alt="bus" />
      </div>

      <Btn color="black" isFixedBottom={true} onClick={() => navigate('form')}>
        Подать заявку
      </Btn>
    </div>
  );
};
